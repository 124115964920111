import {
  ArchiveRegular,
  DocumentCssRegular,
  DocumentJavaRegular,
  DocumentJavascriptRegular,
  DocumentPdfRegular,
  DocumentRegular,
  HardDriveRegular,
  RocketRegular,
  SaveCopyRegular,
  SettingsRegular,
  SoundWaveCircleRegular,
  VideoClipRegular,
} from "@fluentui/react-icons";
import {
  FileCodeIcon,
  ImagePixelIcon,
  TextDocumentIcon,
} from "@fluentui/react-icons-mdl2";
import {
  ExcelDocumentIcon,
  PowerPointDocumentIcon,
  WordDocumentIcon,
} from "@fluentui/react-icons-mdl2-branded";

export const STORAGE_KEYS = {
  darkMode: "dark-mode",
  appLang: "app-lang",
  isListBenefitView: "is-list-benefit-view",
  selectedTheme: "selected-theme",
};

export const QUERY_KEYS = {
  userData: "userData",
  benefitCategories: "benefitCategories",
  taxonomies: "taxonomies",
  roles: "roles",
  authorization: "authorization",
  benefitImplementations: "benefitImplementations",
  benefitImplementationPackages: "benefitImplementationPackages",
  insurers: "insurers",
  clients: "clients",
  users: "users",
  clientsByCountry: "clientsByCountry",
  companySummary: "companySummary",
  companyInfos: "companyInfos",
  userAssignments: "userAssignments",
  companyEnrollments: "companyEnrollments",
  companyEmployees: "companyEmployees",
  companyEmployeeViews: "companyEmployeeViews",
  employeeDetails: "employeeDetails",
  dependentDetails: "dependentDetails",
  employeeEnrollmentsByPackage: "employeeEnrollmentsByPackage",
  employeeEnrollmentsByEmployee: "employeeEnrollmentsByEmployee",
  companyPackages: "companyPackages",
  employeeEligibility: "employeeEligibility",
  benefitCategory: "benefitCategory",
  benefitImplementation: "benefitImplementation",
  employeeEnrollment: "employeeEnrollment",
  dependentEnrollment: "dependentEnrollment",
  generateEmployeeTemplate: "generateEmployeeTemplate",
  generateEnrolledEmployeeTemplate: "generateEnrolledEmployeeTemplate",
  generateEmployeeEnrollmentsReportByTimePeriod:
    "generateEmployeeEnrollmentsReportByTimePeriod",
  companyAction: "companyAction",
  userSettings: "userSettings",
  clientAttachments: "clientAttachments",
  allAppImages: "allAppImages",
};

export const MUTATION_KEYS = {
  createBenefitCategory: "createBenefitCategory",
  createBenefitImplementation: "createBenefitImplementation",
  updateBenefitCategory: "updateBenefitCategory",
  updateBenefitImplementation: "updateBenefitImplementation",
  createMultipleBenefitCategories: "createMultipleBenefitCategories",
  createBenefitPackage: "createBenefitPackage",
  createUserAssignment: "createUserAssignment",
  updateUserAssignment: "updateUserAssignment",
  deleteUserAssignment: "deleteUserAssignment",
  createBenefitCategoryAttachment: "createBenefitCategoryAttachment",
  deleteBenefitCategoryAttachment: "deleteBenefitCategoryAttachment",
  deleteBenefitCategory: "deleteBenefitCategory",
  deleteBenefitImplementationAttachment:
    "deleteBenefitImplementationAttachment",
  deleteBenefitImplementation: "deleteBenefitImplementation",
  createBenefitImplementationAttachment:
    "createBenefitImplementationAttachment",
  updateBenefitImplementationAttachment:
    "updateBenefitImplementationAttachment",
  updatePackageDependent: "updatePackageDependent",
  createPackageDependent: "createPackageDependent",
  deletePackageDependent: "deletePackageDependent",
  updateClient: "updateClient",
  suggestDates: "suggestDates",
  updateBenefitImplementationPackage: "updateBenefitImplementationPackage",
  createEmployee: "createEmployee",
  deleteEmployee: "deleteEmployee",
  deleteClient: "deleteClient",
  updateEmployeeAttachment: "updateEmployeeAttachment",
  deleteEmployeeAttachment: "deleteEmployeeAttachment",
  createEmployeeAttachment: "createEmployeeAttachment",
  createEmployeeDependent: "createEmployeeDependent",
  updateEmployeeDependent: "updateEmployeeDependent",
  deleteEmployeeDependent: "deleteEmployeeDependent",
  deleteEmployeeEnrollment: "deleteEmployeeEnrollment",
  createEmployeeEnrollment: "createEmployeeEnrollment",
  updateEmployeeEnrollment: "updateEmployeeEnrollment",
  deleteEmployeeEnrollments: "deleteEmployeeEnrollments",
  createEmployeeEnrollments: "createEmployeeEnrollments",
  updateEmployeeEnrollments: "updateEmployeeEnrollments",
  createEmployeeEligibility: "createEmployeeEligibility",
  updateEmployeeEligibility: "updateEmployeeEligibility",
  deleteEmployeeEligibility: "deleteEmployeeEligibility",
  createEmployeeEligibilities: "createEmployeeEligibilities",
  updateEmployeeEligibilities: "updateEmployeeEligibilities",
  deleteEmployeeEligibilities: "deleteEmployeeEligibilities",
  createEmployeeDependentEnrollment: "createEmployeeDependentEnrollment",
  updateEmployeeDependentEnrollment: "updateEmployeeDependentEnrollment",
  deleteEmployeeDependentEnrollment: "deleteEmployeeDependentEnrollment",
  addEmployeeTags: "addEmployeeTags",
  removeEmployeeTags: "removeEmployeeTags",
  createCategoryAttachment: "createCategoryAttachment",
  deleteCategoryAttachment: "deleteCategoryAttachment",
  updateCategoryAttachment: "updateCategoryAttachment",
  createPackageAttachment: "createPackageAttachment",
  deletePackageAttachment: "deletePackageAttachment",
  deletePackage: "deletePackage",
  updatePackageAttachment: "updatePackageAttachment",
  createDependentEligibility: "createDependentEligibility",
  updateDependentEligibility: "updateDependentEligibility",
  deleteDependentEligibility: "deleteDependentEligibility",
  importEmployeesFromExcel: "importEmployeesFromExcel",
  addUserSettings: "addUserSettings",
  updateUserSettings: "updateUserSettings",
  deleteUserSettings: "deleteUserSettings",
  updateClientAttachment: "updateClientAttachment",
  deleteClientAttachment: "deleteClientAttachment",
  generateEmployeeEnrollmentsReportByTimePeriod:
    "generateEmployeeEnrollmentsReportByTimePeriod",
  deleteAppImage: "deleteAppImage",
  updateEmployeeEnrollmentStatus: "updateEmployeeEnrollmentStatus",
  updateDependentEnrollmentStatus: "updateDependentEnrollmentStatus",
};

export const ROUTE_PARAMS = {};

export const fileExtensionIcons = {
  // PowerPoint extensions
  pptx: <PowerPointDocumentIcon />,
  ppt: <PowerPointDocumentIcon />,
  ppsx: <PowerPointDocumentIcon />,
  pps: <PowerPointDocumentIcon />,
  potx: <PowerPointDocumentIcon />,
  pot: <PowerPointDocumentIcon />,

  // Excel extensions
  xlsx: <ExcelDocumentIcon />,
  xls: <ExcelDocumentIcon />,
  xlsm: <ExcelDocumentIcon />,
  xltx: <ExcelDocumentIcon />,
  xlt: <ExcelDocumentIcon />,

  // Word extensions
  docx: <WordDocumentIcon />,
  doc: <WordDocumentIcon />,
  dotx: <WordDocumentIcon />,
  dot: <WordDocumentIcon />,

  // PDF
  pdf: <DocumentPdfRegular />,

  // Images
  jpg: <ImagePixelIcon />,
  jpeg: <ImagePixelIcon />,
  png: <ImagePixelIcon />,
  gif: <ImagePixelIcon />,
  bmp: <ImagePixelIcon />,
  svg: <ImagePixelIcon />,
  tiff: <ImagePixelIcon />,

  // Videos
  mp4: <VideoClipRegular />,
  avi: <VideoClipRegular />,
  mkv: <VideoClipRegular />,
  mov: <VideoClipRegular />,
  wmv: <VideoClipRegular />,

  // Audio
  mp3: <SoundWaveCircleRegular />,
  wav: <SoundWaveCircleRegular />,
  flac: <SoundWaveCircleRegular />,
  aac: <SoundWaveCircleRegular />,
  ogg: <SoundWaveCircleRegular />,

  // Archives
  zip: <ArchiveRegular />,
  rar: <ArchiveRegular />,
  "7z": <ArchiveRegular />,
  tar: <ArchiveRegular />,
  gz: <ArchiveRegular />,

  // Text files
  txt: <TextDocumentIcon />,
  csv: <ExcelDocumentIcon />,
  log: <DocumentRegular />,

  // Code files
  js: <DocumentJavascriptRegular />,
  html: <FileCodeIcon />,
  css: <DocumentCssRegular />,
  json: <FileCodeIcon />,
  xml: <FileCodeIcon />,
  ts: <FileCodeIcon />,
  py: <FileCodeIcon />,
  java: <DocumentJavaRegular />,
  c: <FileCodeIcon />,
  cpp: <FileCodeIcon />,

  // Other
  exe: <RocketRegular />,
  dll: <SettingsRegular />,
  iso: <HardDriveRegular />,
  bak: <SaveCopyRegular />,
};
