import {
  Avatar,
  AvatarProps,
  AvatarSize,
  Popover,
  PopoverTrigger,
} from "@fluentui/react-components";
import { useState } from "react";
import styled from "styled-components";

import { Link, PopoverSurface } from "@fluentui/react-components";

type Props = {
  imgSrc?: string;
  name?: string;
  avatarStyle?: React.CSSProperties;
} & AvatarProps;

export function AvatarWithPreview({
  imgSrc,
  name,
  avatarStyle,
  ...avatarProps
}: Props) {
  const [showImg, setShowImg] = useState(false);

  const handleMouseEnter = () => {
    if (!imgSrc) return;
    setShowImg(true);
  };
  const onMouseLeave = () => {
    setShowImg(false);
  };
  return (
    <Container onMouseEnter={handleMouseEnter} onMouseLeave={onMouseLeave}>
      <Popover open={showImg} positioning={"above-start"}>
        <PopoverTrigger>
          <Avatar
            name={name}
            image={{
              src: imgSrc,
              loading: "lazy",
            }}
            style={{
              cursor: imgSrc ? "pointer" : "inherit",
              objectFit: "cover",
              ...avatarStyle,
            }}
            {...avatarProps}
          />
        </PopoverTrigger>
        <PopoverSurface>
          <Link href={imgSrc} target="_blank">
            <img width={"100px"} src={imgSrc} alt="cover" />
          </Link>
        </PopoverSurface>
      </Popover>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: max-content;
`;
