import { Button } from "@fluentui/react-components";
import { Add16Filled } from "@fluentui/react-icons";
import { ImplementBenefitDialog } from "components/ImplementBenefitDialog/ImplementBenefitDialog";
import { ActionButton, ToolbarContainer } from "components/styled";
import { useRoleType } from "hooks/useRoleType";
import { useAtomValue } from "jotai";
import { devices } from "library/constants";
import { IBenefitCategory } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectedCompanyState } from "store/UICompanyPage";
import styled from "styled-components";

type Props = {
  category: IBenefitCategory;
};

export function CompanyImplementationSubheaderToolbar({ category }: Props) {
  const { t } = useTranslation();
  const [addImplementationOpen, setAddImplementationOpen] = useState(false);
  const selectedCompany = useAtomValue(selectedCompanyState);

  const roles = useRoleType();
  const isReadOnlyUser = roles?.hasReadOnlyRole;

  const onDialogOpenChange = useCallback((state) => {
    setAddImplementationOpen(state);
  }, []);
  return (
    <ToolbarContainer>
      <ActionsWrap>
        <ActionButton
          noBorder
          disabled={isReadOnlyUser}
          appearance="transparent"
          icon={<Add16Filled />}
          onClick={() => {
            setAddImplementationOpen(true);
          }}
        >
          {t("hbh.addBenefitImplementation.label")}
        </ActionButton>
      </ActionsWrap>
      {addImplementationOpen && (
        <ImplementBenefitDialog
          isAddMode
          currencyCode={selectedCompany?.currencyCodeId}
          category={category}
          open={addImplementationOpen}
          setOpen={onDialogOpenChange}
        />
      )}
    </ToolbarContainer>
  );
}

const ActionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  @media only screen and ${devices.md} {
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
  }
`;
