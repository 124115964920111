import { DeleteIcon, SaveIcon } from "@fluentui/react-icons-mdl2";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import SelectField from "components/controls/SelectField";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex, RemoveButton } from "components/styled";
import { formatDate } from "date-fns";
import { Form, Formik } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtomValue } from "jotai";
import {
  EmployeeRowItem,
  ETaxonomy,
  IEmployeeDependent,
  IEmployeeView,
} from "library/types";
import {
  formatDateFieldForPayload,
  formatSelectFieldForPayload,
} from "library/utils";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useCreateEmployeeDependent } from "state/queries/useCreateEmployeeDependent";
import { useDeleteEmployeeDependent } from "state/queries/useDeleteEmployeeDependent";
import { useUpdateEmployeeDependent } from "state/queries/useUpdateEmployeeDependent";
import { selectedCompanyState } from "store/UIHrPage";
import styled from "styled-components";
import * as yup from "yup";
import { BasicFields } from "../FormFields/BasicFields";
import { MainFields } from "../FormFields/MainFields";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { useTranslation } from "react-i18next";

type Props = {
  removeNewDependent?: () => void;
  dependentData?: IEmployeeView;
  addNew?: boolean;
  setOpen?: (open: boolean) => void;
  employeeData?: EmployeeRowItem;
};
export function DependentItem({
  removeNewDependent,
  dependentData,
  employeeData,
  addNew,
  setOpen,
}: Props) {
  const { t } = useTranslation();
  const dependentTypeOptions = useTaxonomyOptions(ETaxonomy.DependentType);
  const selectedCompany = useAtomValue(selectedCompanyState);

  const genderOptions = useTaxonomyOptions(ETaxonomy.Gender);
  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);

  const { mutate: onUpdateEmployeeDependent, isLoading: updateInProgress } =
    useUpdateEmployeeDependent();

  const { mutate: onCreateDependent, isLoading: createInProgress } =
    useCreateEmployeeDependent();

  const { mutate: onDeleteEmployeeDependent, isLoading: deleteInProgress } =
    useDeleteEmployeeDependent();

  const [deleteDependentConfirmationOpen, setDeleteDependentConfirmationOpen] =
    useState(false);

  const toggleDeleteConfirmationState = useCallback(() => {
    setDeleteDependentConfirmationOpen((prev) => !prev);
  }, []);

  const initDependentType = dependentTypeOptions.find(
    (option) => option.value === dependentData?.dependentType
  );

  const initialValues = useMemo(() => {
    if (!dependentData) return {};
    return {
      ...dependentData,
      dependentType: initDependentType,
      dateOfBirth: new Date(dependentData?.dateOfBirth || ""),

      //basic
      genderCodeId: genderOptions.find(
        (genderCode) => genderCode.value === dependentData?.genderCodeId
      ),
      citizenshipCodeId: countryOptions.find(
        (item) => item.value === dependentData?.citizenshipCodeId
      ),
      dependentCoverPhoto: dependentData?.backgroundPicturePath
        ? {
            name: dependentData?.backgroundPictureName,
            path: dependentData?.backgroundPicturePath,
            base64String: dependentData?.backgroundPictureContent,
            type: dependentData?.backgroundPictureMimeType,
          }
        : null,
      employeeDependentTag: dependentData?.employeeDependentTag,
    };
  }, [countryOptions, dependentData, genderOptions, initDependentType]);

  const validationSchema = yup.object().shape({
    dependentType: yup.mixed().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    dateOfBirth: yup.mixed().required(),
    // emailAddress: yup.string().required(),
  });

  const handleDeleteDependent = () => {
    if (!dependentData) return;
    onDeleteEmployeeDependent(
      {
        countryCode: Number(selectedCompany?.countryCode),
        clientInternalNumberGOS: Number(
          selectedCompany?.clientInternalNumberGos
        ),
        employeeLoginEmailAddress:
          dependentData?.employeeLoginEmailAddress || "",

        employeeDependentTag: Number(dependentData?.employeeDependentTag),
        rowVersion: dependentData?.rowVersion || "",
      },
      {
        onSuccess: () => {
          toast.success(t("hbh.dependentDeletedSuccessfully.label"));
          // if (setOpen) {
          //   setOpen(false);
          // }
        },
      }
    );
  };

  const handleSubmit = (values) => {
    const payload = {
      ...dependentData,
      ...values,
      employeeLoginEmailAddress: employeeData?.employeeLoginEmailAddress,
      dependentType: formatSelectFieldForPayload({
        values,
        field: "dependentType",
      }),
      dateOfBirth: formatDate(values.dateOfBirth, "yyyy-MM-dd"),
      genderCodeId: formatSelectFieldForPayload({
        values,
        field: "genderCodeId",
      }),
      citizenshipCodeId: formatSelectFieldForPayload({
        values,
        field: "citizenshipCodeId",
      }),
      ...(values.dateOfBirth
        ? { dateOfBirth: formatDateFieldForPayload(values.dateOfBirth) }
        : {}),
      dateOfExit: formatDateFieldForPayload(values.dateOfExit),

      countryCode: Number(selectedCompany?.countryCode),
      clientInternalNumberGOS: Number(selectedCompany?.clientInternalNumberGos),
      backgroundPictureName: values?.dependentCoverPhoto?.name,
      backgroundPictureContent: values?.dependentCoverPhoto?.base64String,
      backgroundPictureMimeType: values?.dependentCoverPhoto?.type,
      ...(!values?.dependentCoverPhoto?.base64String && {
        backgroundPicturePath: values?.dependentCoverPhoto?.path,
      }),
      rowVersion: dependentData?.rowVersion || "",
    };

    if (dependentData) {
      onUpdateEmployeeDependent(
        {
          countryCode: Number(selectedCompany?.countryCode),
          clientInternalNumberGOS: Number(
            selectedCompany?.clientInternalNumberGos
          ),
          employeeLoginEmailAddress:
            dependentData?.employeeLoginEmailAddress || "",

          employeeDependentTag: Number(dependentData?.employeeDependentTag),
          payload: payload as IEmployeeDependent,
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.dependentUpdatedSuccessfully.label"));
            // if (setOpen) {
            //   setOpen(false);
            // }
          },
        }
      );
    } else {
      onCreateDependent(
        {
          countryCode: Number(selectedCompany?.countryCode),
          clientInternalNumberGOS: Number(
            selectedCompany?.clientInternalNumberGos
          ),
          employeeLoginEmailAddress:
            employeeData?.employeeLoginEmailAddress || "",
          payload: payload as IEmployeeDependent,
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.dependentAddedSuccessfully.label"));
            // if (setOpen) {
            //   setOpen(false);
            // }
            removeNewDependent && removeNewDependent();
          },
        }
      );
    }
  };

  const isLoading = createInProgress || updateInProgress || deleteInProgress;

  return (
    <>
      <Container>
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            handleSubmit(values);
            actions.setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => {
            return (
              <>
                {isLoading && <AppSpinner />}
                <Form>
                  <SelectField
                    required
                    error={errors["dependentType"]}
                    setFieldValue={setFieldValue}
                    value={values["dependentType"]}
                    label="Dependent"
                    options={dependentTypeOptions}
                    name={"dependentType"}
                    showEmptyOption
                  />
                  <MainFields isDependent={true} />
                  <BasicFields isDependent={true} />
                  <Flex wrap="nowrap" justify="space-between">
                    <RemoveBtnWrap>
                      <RemoveButton
                        disabled={isLoading}
                        icon={<DeleteIcon />}
                        onClick={() => {
                          if (removeNewDependent && addNew) {
                            removeNewDependent();
                            return;
                          }
                          setDeleteDependentConfirmationOpen(true);
                        }}
                      >
                        {addNew ? t("greco.cancel") : t("greco.delete")}
                      </RemoveButton>
                    </RemoveBtnWrap>
                    <PrimaryButton
                      disabled={!dirty || isLoading}
                      icon={<SaveIcon />}
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                    >
                      {t("greco.save")}
                    </PrimaryButton>
                  </Flex>
                </Form>
              </>
            );
          }}
        </Formik>
      </Container>
      {deleteDependentConfirmationOpen && dependentData && (
        <ConfirmationDialog
          isOpen={deleteDependentConfirmationOpen}
          toggleOpen={toggleDeleteConfirmationState}
          onConfirm={handleDeleteDependent}
          description={t("greco.dialog.deleteTitle", {
            object: `${dependentData?.firstName} ${dependentData?.lastName}`,
          })}
          confirmLabel={t("greco.delete")}
          title={`${t("hbh.deleteDependent.label")} ${
            dependentData?.firstName
          } ${dependentData?.lastName}`}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  box-sizing: border-box;
  padding: 10px;
`;

const RemoveBtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    width: 100%;
  }
`;
