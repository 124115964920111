import { Add16Filled } from "@fluentui/react-icons";
import { PackageDialog } from "components/PackageDialog/PackageDialog";
import { ActionButton, ToolbarContainer } from "components/styled";
import { useRoleType } from "hooks/useRoleType";
import { IBenefitImplementation } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  benefitImplementation: IBenefitImplementation;
};

export function CompanyPackagesToolbar({ benefitImplementation }: Props) {
  const { t } = useTranslation();
  const [openAddPackage, setOpenAddPackage] = useState(false);

  const roles = useRoleType();
  const isReadOnlyUser = roles?.hasReadOnlyRole;

  const onDialogOpenChange = useCallback((state) => {
    setOpenAddPackage(state);
  }, []);
  return (
    <ToolbarContainer>
      <div />
      <ActionButton
        noBorder
        disabled={isReadOnlyUser}
        appearance="subtle"
        icon={<Add16Filled />}
        onClick={() => {
          setOpenAddPackage(true);
        }}
      >
        {t("hbh.addPackage.label")}
      </ActionButton>
      {openAddPackage && (
        <PackageDialog
          benefitImplementation={
            benefitImplementation as IBenefitImplementation
          }
          open={openAddPackage}
          setOpen={onDialogOpenChange}
        />
      )}
    </ToolbarContainer>
  );
}
