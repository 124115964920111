import { BuildingMultipleRegular } from "@fluentui/react-icons";
import { ComboBoxField } from "components/controls/ComboBoxField";
import InputField from "components/controls/InputField";
import { SearchInput } from "components/controls/SearchInput";
import SelectField from "components/controls/SelectField";
import { Flex } from "components/styled";
import { Form, Formik } from "formik";

const options = [
  {
    text: "Cat",
    value: 1,
    Icon: <BuildingMultipleRegular />,
  },
  {
    text: "Pat",
    value: 2,
    Icon: <BuildingMultipleRegular />,
  },
  {
    text: "Mat",
    value: 3,
    Icon: <BuildingMultipleRegular />,
  },
];

const comboName = "comboboxField";
const selectName = "selectField";
const inputName = "inputField";

export function FormDemo(props) {
  const onSubmit = (data) => console.log(data);
  // make sure formState is read before render to enable the Proxy

  return (
    <div>
      <Formik
        initialValues={{}}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form>
            <Flex gap={"20px"} direction="row" wrap="nowrap" $align="center">
              <Flex width="20%">
                <ComboBoxField
                  value={values[comboName]}
                  error={errors[comboName]}
                  setFieldValue={setFieldValue}
                  label="Combobox field"
                  options={options}
                  name={comboName}
                  required={true}
                />
              </Flex>

              <Flex width="20%">
                <SearchInput
                  label="Search field"
                  name={inputName}
                  required={true}
                />
              </Flex>
            </Flex>
            <button>Submit</button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
