import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
} from "@fluentui/react-components";
import { AddCircleRegular, Dismiss24Regular } from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { ActionButton } from "components/styled";
import { devices } from "library/constants";
import { EmployeeRowItem, IEmployeeView } from "library/types";
import { useState } from "react";
import { useGetDependentDetails } from "state/queries/useGetDependentDetails";
import styled from "styled-components";
import { DependentItem } from "./DependentItem";
import { useTranslation } from "react-i18next";

type Props = {
  dependentData?: IEmployeeView;
  open: boolean;
  setOpen: (open: boolean) => void;
  singleDependentEdit?: boolean;
  employeeData: EmployeeRowItem;
};

export function EmployeeDependentsDialog({
  dependentData,
  open,
  employeeData,
  setOpen,
  singleDependentEdit,
}: Props) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [addNewDependent, setAddNewDependent] = useState(false);

  const { data: dependentDetails, isLoading } = useGetDependentDetails({
    countryCode: Number(dependentData?.countryCode),
    clientInternalNumberGOS: Number(dependentData?.clientInternalNumberGos),
    employeeDependentTag: Number(dependentData?.employeeDependentTag),
    employeeLoginEmailAddress: dependentData?.employeeLoginEmailAddress || "",
    enabled: !!singleDependentEdit && !!dependentData,
  });

  const dependentList = singleDependentEdit
    ? [dependentDetails]
    : employeeData?.dependents;

  const title = singleDependentEdit
    ? `${dependentData?.firstName} ${dependentData?.lastName}`
    : `${employeeData?.firstName} ${employeeData?.lastName}`;

  if (isLoading) {
    return <AppSpinner />;
  }
  return (
    <Dialog
      modalType="alert"
      open={open}
      onOpenChange={(event, data) => setOpen(data.open)}
    >
      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogBody className={styles.content}>
          <DialogTitle
            action={
              <DialogTrigger action="close">
                <Button
                  appearance="subtle"
                  aria-label="close"
                  icon={<Dismiss24Regular />}
                />
              </DialogTrigger>
            }
          >
            {title}
          </DialogTitle>

          <DialogContent className={styles.content}>
            {addNewDependent ? (
              <DependentItem
                addNew
                employeeData={employeeData}
                dependentData={dependentData}
                removeNewDependent={() => setAddNewDependent(false)}
                setOpen={setOpen}
              />
            ) : (
              <>
                {singleDependentEdit ? null : (
                  <ActionButton
                    noBorder
                    appearance="subtle"
                    icon={<AddCircleRegular />}
                    onClick={() => {
                      //   const id = genUUID();

                      setAddNewDependent(true);
                    }}
                  >
                    {t("hbh.addDependent.label")}
                  </ActionButton>
                )}
              </>
            )}
            {dependentList?.map((dependent) => (
              <DependentItem
                dependentData={dependent}
                key={dependent?.employeeDependentTag}
                setOpen={setOpen}
                employeeData={employeeData}
              />
            ))}
          </DialogContent>
        </DialogBody>
      </StyledDialogSurface>
    </Dialog>
  );
}
const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 860px;
    min-width: 860px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "860px",
  },
  content: {
    maxHeight: "90vh",

    scrollbarWidth: "thin",
  },
});
