import SelectField from "components/controls/SelectField";
import { checkedOptions } from "library/constants";
import { ECheckedValue, IRowItem } from "library/types";
import { useTranslation } from "react-i18next";

type Props = {
  item: IRowItem;
  readOnly?: boolean;
};

export function EnrollmentProcessNeeded({ item, readOnly }: Props) {
  const { t } = useTranslation();
  const value = {
    text: item.enrollmentProcessNeeded ? t("hbh.yes.label") : t("hbh.no.label"),
    value: item.enrollmentProcessNeeded ? ECheckedValue.Yes : ECheckedValue.No,
  };

  if (readOnly) {
    return value?.text;
  }

  return (
    <SelectField
      onClick={(e) => {
        e.stopPropagation();
      }}
      error={""}
      setFieldValue={(name, value) => {
        const isYesValue = value.value === ECheckedValue.Yes;

        const updatedItem = {
          ...item,
          isEdited: true,

          enrollmentProcessNeeded: isYesValue,
        };
        item.updateState(updatedItem);
      }}
      value={value as any}
      label=""
      options={checkedOptions}
      name={"enrollmentProcessNeeded"}
      noErrorSection
      noLabel
    />
  );
}
