import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployeeDependent } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateDependentDetails } from "./useGetDependentDetails";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  employeeDependentTag: number | string;
  payload: IEmployeeDependent;
  employeeLoginEmailAddress: string;
};

const updateEmployeeDependent = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeDependentTag,
  employeeLoginEmailAddress,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employee/dependent/${countryCode}/${clientInternalNumberGOS}/${employeeLoginEmailAddress}/${employeeDependentTag}`,
    payload
  );
  return response.data as IEmployeeDependent;
};

export function useUpdateEmployeeDependent(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const invalidateDependentDetails = useInvalidateDependentDetails();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(updateEmployeeDependent, {
    mutationKey: MUTATION_KEYS.updateEmployeeDependent,
    onSuccess: () => {
      if (refetch) {
        invalidateCompanyEmployees();
        invalidateDependentDetails();
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeDetails,
        });
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
