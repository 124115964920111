import { Persona } from "@fluentui/react-components";
import debounce from "debounce-promise";
import { useAppTheme } from "hooks/useAppTheme";
import { useRoleType } from "hooks/useRoleType";
import { useAtom } from "jotai";
import { IUserAssignment, SelectItem } from "library/types";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { getUsers } from "state/queries/useGetUsers";
import {
  searchUserInputOptionAtom,
  searchUserInputValueAtom,
} from "store/UIHrPage";

type Props = {
  selectedUser: IUserAssignment | null;
  userAssignmentList: IUserAssignment[];
  onSelectUser: (user: IUserAssignment | null) => void;
};

export function SelectUser({
  selectedUser,
  onSelectUser,
  userAssignmentList,
}: Props) {
  const theme = useAppTheme();
  const isAdminUser = useRoleType()?.hasAdminRole;
  const [searchUserOption, setSearchUserOption] = useAtom(
    searchUserInputOptionAtom
  );
  const [searchUserInputValue, setSearchUserInputValue] = useAtom(
    searchUserInputValueAtom
  );

  const selectRef = useRef(null);
  const { t } = useTranslation();

  const disabled = false;

  const error = false;

  const onChange = (option, state) => {
    if (option) {
      setTimeout(() => {
        setSearchUserInputValue(option.text);
      }, 100); //hack to set input value after select because async select is clearing input value after change
    }

    if (state.action === "clear") {
      setSearchUserOption(null);
      setSearchUserInputValue("");
      if (isAdminUser) {
        onSelectUser(null);
      }
      return;
    }

    const userAlreadyExist = userAssignmentList.find(
      (user) => user?.userId === option?.userId
    );

    if (userAlreadyExist) {
      setSearchUserOption(option);
    } else {
      setSearchUserOption(option);
      if (isAdminUser) {
        onSelectUser(option);
      }
    }
  };

  React.useLayoutEffect(() => {
    const inputEl = document.getElementById("asyncSelectUser");
    if (!inputEl) return;
    // prevent input from being hidden after selecting which is default async select behavior
    inputEl.style.opacity = "1";
  }, [searchUserOption]);

  return (
    <AsyncSelect
      inputId="asyncSelectUser"
      ref={selectRef}
      isSearchable
      isDisabled={disabled}
      cacheOptions
      placeholder={t("hbh.selectUser.label")}
      loadingMessage={() => t("greco.loading")}
      noOptionsMessage={() => t("greco.noResults")}
      inputValue={searchUserInputValue}
      onInputChange={(inputValue, { action }) => {
        if (action === "input-change") {
          setSearchUserInputValue(searchUserOption?.text || inputValue);
        }
      }}
      defaultOptions={false}
      isClearable={true}
      onChange={onChange}
      value={selectedUser || searchUserOption}
      components={{ Option: CustomOption }}
      // value={selectedCompany as any}
      loadOptions={debounce(async (input: string) => {
        const trimmedFilter = input.trim();
        if (trimmedFilter.length === 0) return [];
        const userList = await getUsers({
          letters: trimmedFilter,
        });
        const options = (userList || [])?.map((option) => {
          return {
            text: option?.userName?.toString(),
            value: option?.userId?.toString(),
            ...option,
          };
        });

        return options;
      }, 500)}
      name={"users"}
      styles={{
        container: (baseStyles) => ({
          ...baseStyles,
          position: "relative",
          minWidth: "300px",
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          // background: "transparent",
          background: theme.palette.themeLighterAlt,
          border: (() => {
            if (state.isFocused) {
              return `1px solid ${theme.palette.neutralLight}`;
            }

            return `1px solid ${theme.palette.neutralLight}`;
          })(),

          borderColor: theme.palette.neutralLight,
          borderRadius: "3px",
          height: "32px !important",
          minHeight: "32px !important",
          borderBottom: (() => {
            if (error) {
              return `2px solid ${theme.palette.redDark} !important`;
            }
            if (state.isFocused) {
              return `2px solid ${theme.palette.themePrimary} !important`;
            }

            return `2px solid ${theme.palette.neutralLight}`;
          })(),
          "&:hover": {
            border: `1px solid ${theme.palette.neutralLight}`,
          },
          boxShadow: "none",
        }),
        menu: (p) => ({
          ...p,
          background: theme.palette.neutralLighter,
          zIndex: 5,
        }),
        option: (p, state) => ({
          ...p,
          background:
            state.isSelected || state.isFocused
              ? theme.palette.neutralLighter
              : theme.palette.white,
          "&:hover": {
            background: theme.palette.neutralLighter,
          },
          padding: "4px 12px",
          color: theme.palette.black,
          fontSize: "14px",
          fontWeight: 400,
        }),
        input: (p) => ({
          ...p,
          margin: "4px",
          color: (function () {
            if (disabled) {
              return theme.palette.neutralSecondaryAlt;
            }

            return theme.palette.black;
          })(),
        }),
        valueContainer: (p, state) => ({
          ...p,
          padding: "1px 8px !important",
        }),
        placeholder: (p, state) => ({
          ...p,
          padding: "1px 8px !important",
        }),
        singleValue: (p, state) => ({
          ...p,
          fontSize: "14px",
          marginLeft: "5px",
          fontWeight: 400,
          color: theme.palette.black,
        }),
        dropdownIndicator: (p, state) => ({
          ...p,
          ...(state.isDisabled && { visibility: "hidden" }),
        }),
        indicatorsContainer: (p, state) => ({
          ...p,
          height: "32px !important",
          minHeight: "32px !important",
        }),
        indicatorSeparator: (p, state) => ({
          ...p,
          visibility: "hidden",
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
          overflow: "visible",
        }),
      }}
    />
  );
}

const CustomOption = ({ children, ...props }: any) => {
  const userData = props.data as SelectItem & IUserAssignment;
  return (
    <components.Option {...props}>
      <Persona
        name={userData.userName}
        secondaryText={userData.userEmailAddress}
        {...props}
      />
    </components.Option>
  );
};
