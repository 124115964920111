import { CameraAddRegular, ImageEditRegular } from "@fluentui/react-icons";
import { ActionButton } from "components/styled";
import { useRoleType } from "hooks/useRoleType";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ManageImagesDialog } from "./ManageImagesDialog";

type Props = {
  onSelectImage?: (event: any, image?: string) => void;
};

export function ManageImages({ onSelectImage }: Props) {
  const { t } = useTranslation();
  const roles = useRoleType();

  const isReadOnlyUser = !roles?.hasAdminRole;

  const [manageImagesOpen, setManageImagesOpen] = useState(false);

  const handleManageImagesOpenState = useCallback((state) => {
    setManageImagesOpen(state);
  }, []);

  const title = onSelectImage
    ? t("hbh.selectImage.label")
    : t("hbh.manageImages.label");

  return (
    <>
      <ActionButton
        noBorder
        disabled={isReadOnlyUser}
        appearance={onSelectImage ? "transparent" : "subtle"}
        icon={onSelectImage ? <CameraAddRegular /> : <ImageEditRegular />}
        style={{
          ...(onSelectImage
            ? {
                paddingLeft: 0,
              }
            : {}),
        }}
        onClick={() => {
          setManageImagesOpen(true);
        }}
      >
        {title}
      </ActionButton>
      {manageImagesOpen && (
        <ManageImagesDialog
          onSelectImage={onSelectImage}
          open={manageImagesOpen}
          setOpen={handleManageImagesOpenState}
        />
      )}
    </>
  );
}
