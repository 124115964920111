import axios from "axios";
import { MSGraphAuth } from "./GraphService";
import { ProjectParams } from "../config/GraphConfig";
import { urlFormat } from "../library/utils";
import { PowerBIEmbedMeta } from "library/types";
import { ROUTES } from "library/constants";

let authHeaders = {};

const url = urlFormat(process.env.REACT_APP_BASE_URL || "");

const axiosInstance = axios.create({
  baseURL: url,
});

async function execute(func: any, url: string, config: {}) {
  try {
    const response = await func(url, config);
    return {
      status: response?.status,
      statusText: response.statusText,
      data: response.data,
    };
  } catch (error: any) {
    if (error.response?.status === 403) {
      window.location.href = ROUTES.UNAUTHORIZED_PAGE;
    }
    // if (error.response) {
    //   const err = {
    //     status: error?.response?.status,
    //     statusText: error?.response?.statusText,
    //     error:
    //       error?.response?.data?.error || error?.response?.data?.data?.error,
    //     message:
    //       error?.response?.data?.message ||
    //       error?.response?.data?.data?.message ||
    //       "",
    //   };

    //   throw err;
    // }

    throw error;
  }
}

const api = {
  setAuthHeaders: (headers: {}) => {
    authHeaders = {
      ...authHeaders,
      ...headers,
    };
  },
  get: async (url: string, config = {}) =>
    execute(axiosInstance.get, url, config),
  post: async (url: string, config = {}) =>
    execute(axiosInstance.post, url, config),
  put: async (url: string, config = {}) =>
    execute(axiosInstance.put, url, config),
  patch: async (url: string, config = {}) =>
    execute(axiosInstance.patch, url, config),
  delete: async (url: string, config = {}) =>
    execute(axiosInstance.delete, url, config),
};

export default api;

axiosInstance.interceptors.request.use(async (config) => {
  let authResponse = await MSGraphAuth.getAccessToken(ProjectParams);
  if (!authResponse) return config;
  if (config.url?.indexOf("thumbnailPhoto") !== -1) {
    return config;
  }
  config!.headers!.Authorization = `Bearer ${authResponse.accessToken}`;
  return config;
});

export const getPowerBIEmbedMeta = async (
  countryId: string | null,
  clientIds: string | null
) => {
  var allOrSelectedCountry = countryId ?? "0";
  var allOrSelectedClientInternalNumberGos = clientIds ?? "all";
  const res = await axiosInstance.get<PowerBIEmbedMeta>(
    `${url}/powerbi/${allOrSelectedCountry}/${allOrSelectedClientInternalNumberGos}`
  );
  return res;
};

export const exportPowerBIReport = async (data: any) => {
  const res = await axiosInstance.post(`${url}/powerbi/export`, data);
  return res;
};
