import { atom } from "jotai";
import { SelectItem } from "library/types";
import moment from "moment";

export const filterCurrencyAtom = atom<SelectItem | null>({
  code: "Currency.EUR",
  text: "Euro",
  value: "378",
});

export const filterPaymentMethodAtom = atom<SelectItem | null>({
  code: "PaymentMethod.All",
  text: "All Payment Methods",
  value: "",
});

export const filterPaymentTypeAtom = atom<SelectItem | null>({
  code: "PaymentType.All",
  text: "All Payment Types",
  value: "",
});

export const reportDateAtom = atom(new Date());

export const periodFromDateAtom = atom(
  moment(moment().get("year") + "-01-01T00:00:00Z").toString()
);
export const periodToDateAtom = atom(new Date());

export const historicReportAtom = atom(false);

export const selectedBenefitsAtom = atom<Set<string>>(new Set<string>());
export const expandedBenefitsAtom = atom<Set<string>>(new Set<string>());
