import { Card, Text, Subtitle2 } from "@fluentui/react-components";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { useAtom, useAtomValue } from "jotai";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useGetCompanyInfos } from "state/queries/useGetCompanyInfos";
import { selectedCompanyState } from "store/UIHrPage";
import {
  filterCurrencyAtom,
  filterPaymentMethodAtom,
  filterPaymentTypeAtom,
  historicReportAtom,
  periodFromDateAtom,
  periodToDateAtom,
  reportDateAtom,
  selectedBenefitsAtom,
} from "store/UIHrPageDashboard";
import styled from "styled-components";
import FilterSection from "./FilterSection/FilterSection";
import { formattedNumber, vibrantColors } from "./utils";
import { devices } from "library/constants";
import { useTranslation } from "react-i18next";
import useGetAllCurrencyOptions from "hooks/useGetAllCurrencyOptions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const { t } = useTranslation();
  const selectedCompany = useAtomValue(selectedCompanyState);
  const reportDate = useAtomValue(reportDateAtom);
  const historicReport = useAtomValue(historicReportAtom);
  const selectedBenefits = useAtomValue(selectedBenefitsAtom);
  const filterPaymentMethod = useAtomValue(filterPaymentMethodAtom);
  const filterPaymentType = useAtomValue(filterPaymentTypeAtom);
  const [filterCurrency, setFilterCurrency] = useAtom(filterCurrencyAtom);
  const { data: companyInfo, isFetching: loadingInfo } = useGetCompanyInfos({
    countryCode: selectedCompany?.countryCode || null,
    clientInternalNumberGOS: selectedCompany?.clientInternalNumberGos || "",
    onDate: historicReport ? moment(reportDate).format("YYYY-MM-DD") : "now",
    enabled: !!selectedCompany,
  });
  const periodFromDate = useAtomValue(periodFromDateAtom);
  const periodToDate = useAtomValue(periodToDateAtom);
  const allCurrencyOptions = useGetAllCurrencyOptions();

  useEffect(() => {
    if (selectedCompany) {
      const currencyCodeOption = allCurrencyOptions?.find(
        (currency) => currency.value === selectedCompany?.currencyCode
      );

      setFilterCurrency(
        currencyCodeOption ?? {
          code: "Currency.EUR",
          text: "Euro",
          value: "378",
        }
      );
    }
  }, [selectedCompany]);
  const filteredCompanyInfo = useMemo(() => {
    if (!companyInfo) return null;
    const implementationsWithGivenCurrency = companyInfo?.benefitImplementations
      ?.filter((imp) => imp.currencyCode + "" === filterCurrency?.value + "")
      .map((imp) => imp.benefitImplementationTag);

    const selectedCategoryTags = Array.from(selectedBenefits)
      .filter((b) => b.includes("_package"))
      .map((p, index) => {
        const categoryTag = companyInfo?.benefitPackages?.find(
          (bp) =>
            bp.benefitPackageTag + "" === (p + "").replaceAll("_package", "")
        )?.benefitCategoryTag;
        return categoryTag;
      });
    // make list of unique category tags
    const uniqueCategoryTags = Array.from(new Set(selectedCategoryTags));
    // make list of categries based on unique category tags
    const selectedCategories = companyInfo?.benefitCategories?.filter((bc) =>
      uniqueCategoryTags.includes(bc.benefitCategoryTag)
    );

    let filteredEmployeeEnrollments = companyInfo?.employeeEnrollments
      ?.filter((en) =>
        Array.from(selectedBenefits).includes(en.benefitPackageTag + "_package")
      )
      .filter((en) => {
        return en.dateOfEnrollment !== null;
      })
      .filter((en) => {
        return implementationsWithGivenCurrency?.includes(
          (en as any).benefitImplementationTag
        );
      })
      .filter(
        (en) =>
          filterPaymentMethod?.value === "" ||
          en.paymentMethod === filterPaymentMethod?.value
      )
      .filter(
        (en) =>
          filterPaymentType?.value === "" ||
          en.paymentType === filterPaymentType?.value
      )
      .map((en) => {
        const dol = en.dateOfEligibility
          ? new Date(en.dateOfEligibility)
          : null;
        const doe = en.dateOfEnrollment ? new Date(en.dateOfEnrollment) : null;
        const doc = en.dateOfCancellation
          ? new Date(en.dateOfCancellation)
          : null;
        const ps = new Date(periodFromDate);
        const pe = new Date(periodToDate);

        const isNewlyEligible = dol ? dol >= ps && dol <= periodToDate : false;
        const isNewlyEnrolled = doe ? doe >= ps && doe <= periodToDate : false;
        const isNewlyCancelled = doc ? doc >= ps && doc <= periodToDate : false;

        const isActiveInPeriod = doe
          ? (doe < ps &&
              (doc === null || doc >= ps) &&
              (doc === null || doc < pe)) ||
            (doe >= ps && doe < pe && (doc === null || doc >= pe)) ||
            (doe >= ps && (doc === null || doc < pe)) ||
            (doe < ps && (doc === null || doc >= pe))
          : false;

        const benefitImplementation = companyInfo?.benefitImplementations?.find(
          (el) =>
            el.benefitImplementationTag ===
            companyInfo?.benefitPackages?.find(
              (bp) => bp.benefitPackageTag === en.benefitPackageTag
            )?.benefitImplementationTag
        );
        const prorataFactor = isActiveInPeriod
          ? calculateProrataFactor(
              ps,
              periodToDate,
              doe,
              doc,
              benefitImplementation?.validFrom + "",
              benefitImplementation?.validTo + ""
            )
          : 0;

        return {
          ...en,
          prorataFactor,
          isNewlyEligible,
          isNewlyEnrolled,
          isNewlyCancelled,
          isActiveInPeriod,
        };
      });

    let filteredEmployeeEligibilities = companyInfo?.employeeEnrollments
      ?.filter((en) =>
        Array.from(selectedBenefits).includes(en.benefitPackageTag + "_package")
      )
      .filter((en) => {
        return en.dateOfEnrollment === null;
      })
      .filter((en) => {
        return implementationsWithGivenCurrency?.includes(
          (en as any).benefitImplementationTag
        );
      })
      .filter(
        (en) =>
          filterPaymentMethod?.value === "" ||
          en.paymentMethod === filterPaymentMethod?.value
      )
      .filter(
        (en) =>
          filterPaymentType?.value === "" ||
          en.paymentType === filterPaymentType?.value
      )
      .map((en) => {
        const dol = en.dateOfEligibility
          ? new Date(en.dateOfEligibility)
          : null;
        const doe = en.dateOfEnrollment ? new Date(en.dateOfEnrollment) : null;
        const doc = en.dateOfCancellation
          ? new Date(en.dateOfCancellation)
          : null;
        const ps = new Date(periodFromDate);
        const pe = new Date(periodToDate);

        const isNewlyEligible = dol ? dol >= ps && dol <= periodToDate : false;
        const isNewlyEnrolled = doe ? doe >= ps && doe <= periodToDate : false;
        const isNewlyCancelled = doc ? doc >= ps && doc <= periodToDate : false;

        const isActiveInPeriod = doe
          ? (doe < ps &&
              (doc === null || doc >= ps) &&
              (doc === null || doc < pe)) ||
            (doe >= ps && doe < pe && (doc === null || doc >= pe)) ||
            (doe >= ps && (doc === null || doc < pe)) ||
            (doe < ps && (doc === null || doc >= pe))
          : false;

        const benefitImplementation = companyInfo?.benefitImplementations?.find(
          (el) =>
            el.benefitImplementationTag ===
            companyInfo?.benefitPackages?.find(
              (bp) => bp.benefitPackageTag === en.benefitPackageTag
            )?.benefitImplementationTag
        );
        const prorataFactor = isActiveInPeriod
          ? calculateProrataFactor(
              ps,
              periodToDate,
              doe,
              doc,
              benefitImplementation?.validFrom + "",
              benefitImplementation?.validTo + ""
            )
          : 0;

        return {
          ...en,
          prorataFactor,
          isNewlyEligible,
          isNewlyEnrolled,
          isNewlyCancelled,
          isActiveInPeriod,
        };
      });

    const retVal = {
      ...companyInfo,
      filteredEmployeeEnrollments: filteredEmployeeEnrollments,
      companyCosts: filteredEmployeeEnrollments?.reduce((accumulator, en) => {
        return (
          accumulator +
          en.prorataFactor *
            (en.usingPercentagesForPaidBy
              ? (en.paidByCompany / 100) * en.price
              : en.paidByCompany)
        );
      }, 0),
      employeeCosts: filteredEmployeeEnrollments?.reduce((accumulator, en) => {
        return (
          accumulator +
          en.prorataFactor *
            (en.usingPercentagesForPaidBy
              ? (en.paidByEmployee / 100) * en.price
              : en.paidByEmployee)
        );
      }, 0),
      // count unique employees from enrollments (excluding dependents)
      employeeEnrollmentsCount: Array.from(
        new Set(filteredEmployeeEnrollments?.filter((en) => !en.isDependent))
      ).length,
      totalEmployeeEnrollmentsCount:
        Array.from(
          new Set(filteredEmployeeEnrollments?.filter((en) => !en.isDependent))
        ).length +
        Array.from(
          new Set(
            filteredEmployeeEligibilities?.filter((en) => !en.isDependent)
          )
        ).length,

      totalEmployeesCount: companyInfo?.employees?.filter(
        (em) =>
          moment(em.dateOfEmployment).isBefore(periodToDate) &&
          (em.dateOfExit === null ||
            moment(em.dateOfExit).isAfter(periodFromDate))
      ).length,
      // count unique dependents from enrollments
      dependentEnrollmentsCount: Array.from(
        new Set(filteredEmployeeEnrollments?.filter((en) => en.isDependent))
      ).length,
      totalDependentEnrollmentsCount:
        Array.from(
          new Set(filteredEmployeeEnrollments?.filter((en) => en.isDependent))
        ).length +
        Array.from(
          new Set(filteredEmployeeEligibilities?.filter((en) => en.isDependent))
        ).length,
      totalDependentsCount: companyInfo?.employees
        ?.filter(
          (em) =>
            moment(em.dateOfEmployment).isBefore(periodToDate) &&
            (em.dateOfExit === null ||
              moment(em.dateOfExit).isAfter(periodFromDate))
        )
        .reduce((accumulator, employee) => {
          return (
            accumulator + (employee.dependents ? employee.dependents.length : 0)
          );
        }, 0),
      // grouped by category, implementation, package
      paidByEmployeeChartData: {
        labels: [
          t("hbh.dashboard.forEmployees.label"),
          t("hbh.dashboard.forDependents.label"),
        ],
        datasets: selectedCategories?.map((category, index) => {
          const color = vibrantColors[index];
          return {
            label: (category as any).name,
            backgroundColor: color.backgroundColor,
            borderColor: color.borderColor,
            borderWidth: 1,
            data: [
              filteredEmployeeEnrollments
                ?.filter(
                  (en) =>
                    (en as any).benefitCategoryTag ===
                      category?.benefitCategoryTag && en.dependentType === null
                )
                ?.reduce((accumulator, en) => {
                  return (
                    accumulator +
                    en.prorataFactor *
                      (en.usingPercentagesForPaidBy
                        ? (en.paidByEmployee / 100) * en.price
                        : en.paidByEmployee)
                  );
                }, 0),
              filteredEmployeeEnrollments
                ?.filter(
                  (en) =>
                    (en as any).benefitCategoryTag ===
                      category?.benefitCategoryTag && en.dependentType !== null
                )
                ?.reduce((accumulator, en) => {
                  return (
                    accumulator +
                    en.prorataFactor *
                      (en.usingPercentagesForPaidBy
                        ? (en.paidByEmployee / 100) * en.price
                        : en.paidByEmployee)
                  );
                }, 0),
            ],
          };
        }),
      },
      paidByCompanyChartData: {
        labels: [
          t("hbh.dashboard.forEmployees.label"),
          t("hbh.dashboard.forDependents.label"),
        ],
        datasets: selectedCategories?.map((category, index) => {
          const color = vibrantColors[index];
          return {
            label: (category as any).name,
            backgroundColor: color.backgroundColor,
            borderColor: color.borderColor,
            borderWidth: 1,
            data: [
              filteredEmployeeEnrollments
                ?.filter(
                  (en) =>
                    (en as any).benefitCategoryTag ===
                      category?.benefitCategoryTag && en.dependentType === null
                )
                ?.reduce((accumulator, en) => {
                  return (
                    accumulator +
                    en.prorataFactor *
                      (en.usingPercentagesForPaidBy
                        ? (en.paidByCompany / 100) * en.price
                        : en.paidByCompany)
                  );
                }, 0),
              filteredEmployeeEnrollments
                ?.filter(
                  (en) =>
                    (en as any).benefitCategoryTag ===
                      category?.benefitCategoryTag && en.dependentType !== null
                )
                ?.reduce((accumulator, en) => {
                  return (
                    accumulator +
                    en.prorataFactor *
                      (en.usingPercentagesForPaidBy
                        ? (en.paidByCompany / 100) * en.price
                        : en.paidByCompany)
                  );
                }, 0),
            ],
          };
        }),
      },
    };
    return retVal;
  }, [
    companyInfo,
    selectedBenefits,
    filterCurrency,
    filterPaymentMethod,
    filterPaymentType,
    periodFromDate,
    periodToDate,
  ]);

  return (
    <Container>
      <FilterSection companyInfo={companyInfo} isLoading={loadingInfo} />
      <Content>
        <ContentWrap3>
          <div>
            <Card style={{ padding: "20px" }}>
              <Subtitle2>
                {t("hbh.dashboard.employeesTotal.label")}:{" "}
                {filteredCompanyInfo?.totalEmployeesCount ?? "0"}
              </Subtitle2>
              <Subtitle2>
                {t("hbh.dashboard.dependentsTotal.label")}:{" "}
                {filteredCompanyInfo?.totalDependentsCount ?? "0"}
              </Subtitle2>
            </Card>
          </div>
          <div>
            <Card style={{ padding: "20px" }}>
              <Subtitle2>
                {t("hbh.dashboard.employeeEnrollmentsTotal.label")}:{" "}
                {filteredCompanyInfo?.employeeEnrollmentsCount ?? "0"}/
                {filteredCompanyInfo?.totalEmployeeEnrollmentsCount ?? "0"}
              </Subtitle2>
              <Subtitle2>
                {t("hbh.dashboard.dependentEnrollmentsTotal.label")}:{" "}
                {filteredCompanyInfo?.dependentEnrollmentsCount ?? "0"}/
                {filteredCompanyInfo?.totalDependentEnrollmentsCount ?? "0"}
              </Subtitle2>
            </Card>
          </div>
          <div>
            <Card style={{ padding: "20px" }}>
              <Subtitle2>
                {t("hbh.dashboard.companyCosts.label")}:{" "}
                {filteredCompanyInfo?.companyCosts
                  ? formattedNumber.format(
                      filteredCompanyInfo?.companyCosts as number
                    )
                  : null}
              </Subtitle2>
              <Subtitle2>
                {t("hbh.dashboard.employeeCosts.label")}:{" "}
                {filteredCompanyInfo?.employeeCosts
                  ? formattedNumber.format(
                      filteredCompanyInfo?.employeeCosts as number
                    )
                  : null}
              </Subtitle2>
            </Card>
          </div>
        </ContentWrap3>
        <ContentWrap2>
          <Card style={{ padding: "20px" }}>
            <Text>{t("hbh.dashboard.paidByEmployee.label")}</Text>
            {loadingInfo || !filteredCompanyInfo ? (
              <div
                style={{
                  height: `calc(100vh - ${600 - 44}px)`,
                  overflowY: "scroll",
                }}
              />
            ) : (
              <Bar
                data={{
                  labels:
                    filteredCompanyInfo?.paidByEmployeeChartData?.labels ?? [],
                  datasets:
                    filteredCompanyInfo?.paidByEmployeeChartData?.datasets ??
                    [],
                }}
                options={{
                  responsive: true,
                  plugins: { legend: { position: "top" } },
                }}
              />
            )}
          </Card>
          <Card style={{ padding: "20px" }}>
            <Text>{t("hbh.dashboard.paidByCompany.label")}</Text>
            {loadingInfo || !filteredCompanyInfo ? (
              <div
                style={{
                  height: `calc(100vh - ${600 - 44}px)`,
                  overflowY: "scroll",
                }}
              />
            ) : (
              <Bar
                data={{
                  labels:
                    filteredCompanyInfo?.paidByCompanyChartData?.labels ?? [],
                  datasets:
                    filteredCompanyInfo?.paidByCompanyChartData?.datasets ?? [],
                }}
                options={{
                  responsive: true,
                  plugins: { legend: { position: "top" } },
                }}
              />
            )}
          </Card>
        </ContentWrap2>
      </Content>
      {loadingInfo && <AppSpinner />}
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 20px;

  @media only screen and ${devices.md} {
    grid-template-columns: 1fr 3fr;
  }
`;
const Content = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  gap: 20px;

  @media only screen and ${devices.md} {
    grid-template-rows: auto 1fr;
  }
`;

const ContentWrap3 = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media only screen and ${devices.md} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const ContentWrap2 = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media only screen and ${devices.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

function calculateProrataFactor(
  periodFromDate: Date,
  periodToDate: Date,
  enrollmentDate: Date | null,
  cancellationDate: Date | null,
  packageValidFrom: string,
  packageValidTo: string
) {
  // calculate prorata number of days for the period.
  // Only days between packageValidFrom and packageValidTo are considered.
  // And among them only period days are considered.
  // and among them only days between enrollmentDate and cancellationDate are considered.
  // CancellationDate is not considered if it is null and periodToDate is used instead.
  // EnrollmentDate is not considered if it is null and factor is 0 in this case.
  // If enrollmentDate is after periodToDate then factor is 0.
  // If cancellationDate is before periodFromDate then factor is 0.
  // If cancellationDate is after periodToDate then periodToDate is used instead.

  const periodFromDateUTC = new Date(
    periodFromDate.getFullYear(),
    periodFromDate.getMonth(),
    periodFromDate.getDate(),
    0,
    0,
    0
  );
  const periodToDateUTC = new Date(
    periodToDate.getFullYear(),
    periodToDate.getMonth(),
    periodToDate.getDate(),
    0,
    0,
    0
  );
  const enrollmentDateUTC = enrollmentDate
    ? new Date(
        enrollmentDate.getFullYear(),
        enrollmentDate.getMonth(),
        enrollmentDate.getDate(),
        0,
        0,
        0
      )
    : null;
  let cancellationDateUTC = cancellationDate
    ? new Date(
        cancellationDate.getFullYear(),
        cancellationDate.getMonth(),
        cancellationDate.getDate(),
        0,
        0,
        0
      )
    : null;

  const packageValidFromDate = new Date(packageValidFrom);
  const packageValidToDate = new Date(packageValidTo);

  if (enrollmentDateUTC === null) return 0;
  if (cancellationDateUTC === null) {
    cancellationDateUTC = new Date(packageValidToDate?.getTime());
  }
  if (enrollmentDate && enrollmentDate > periodToDate) return 0;
  if (cancellationDate && cancellationDate < periodFromDate) return 0;

  const periodFromUTC = periodFromDateUTC.getTime();
  const periodToUTC = periodToDateUTC.getTime();
  const enrollmentUTC = enrollmentDateUTC?.getTime();
  const cancellationUTC = cancellationDateUTC?.getTime();
  const packageValidFromUTC = packageValidFromDate.getTime();
  const packageValidToUTC = packageValidToDate.getTime();

  const fromUTC = Math.max(packageValidFromUTC, periodFromUTC);
  const toUTC = Math.min(packageValidToUTC, periodToUTC);

  const finalFromUTC = Math.max(fromUTC, enrollmentUTC);
  const finalToUTC = Math.min(toUTC, cancellationUTC);

  return (
    (finalToUTC - finalFromUTC) / (packageValidToUTC - packageValidFromUTC)
  );
}
