import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string;
  benefitCategoryTag: number | string;
  clientInternalNumberGOS: number | string;
  benefitImplementationTag: number | string;
  attachmentName: string;
  benefitPackageTag: number | string;

  payload: Attachment;
};
const updateAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitCategoryTag,
  benefitImplementationTag,
  attachmentName,
  benefitPackageTag,

  payload,
}: Params) => {
  const response = await api.patch(
    `/benefitpackage/attachment/${countryCode}/${clientInternalNumberGOS}/${benefitCategoryTag}/${benefitImplementationTag}/${benefitPackageTag}/${attachmentName}`,
    payload
  );

  return response.data as Attachment;
};
export function useUpdatePackageAttachment(refetch = true) {
  const queryClient = useQueryClient();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(updateAttachment, {
    mutationKey: MUTATION_KEYS.updatePackageAttachment,
    onSuccess: () => {
      if (refetch) {
        queryClient.refetchQueries({
          queryKey: QUERY_KEYS.benefitImplementationPackages,
        });
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
