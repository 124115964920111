import useNotificationHandler from "hooks/useNotificationHandler";
import { PowerBIEmbedMeta } from "library/types";
import { models, Report } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as API from "service/api";

type Props = {
  countryId: string | null;
  clientIds: string | null;
  isDialog?: boolean;
};

export const Analytics = ({
  countryId,
  clientIds,
  isDialog = false,
}: Props) => {
  const [
    embedMetadata = {
      reportId: undefined,
      accessToken: undefined,
      embedUrl: "",
    },
    setEmbedMetadata,
  ] = useState<PowerBIEmbedMeta>();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  const [report, setReport] = useState<Report>();
  const [reportType, setReportType] = useState<string>();
  const { t } = useTranslation();

  const refreshToken = useCallback(() => {
    (async () => {
      //let tmid = clientsFilter.join(",");
      const refreshEmbedObj: any = await API.getPowerBIEmbedMeta(
        countryId,
        clientIds
      );
      if (report) {
        report.setAccessToken(refreshEmbedObj.data.accessToken);
      }
      setTimeout(refreshToken, 55 * 60 * 1000); // wait 55 minutes before refreshing token
    })();
  }, []);

  useEffect(() => {
    (async () => {
      //let tmid = clientsFilter.join(",");

      const embedObj: any = await API.getPowerBIEmbedMeta(countryId, clientIds);
      setEmbedMetadata(embedObj.data);
    })();
    setTimeout(refreshToken, 55 * 60 * 1000); // wait 55 minutes before refreshing token
  }, [countryId, clientIds, refreshToken]);

  const captureBookmark = async () => {
    if (report) {
      const reportBookmark = await report.bookmarksManager.capture();
      const pages = await report.getPages();
      let data: any = null;
      pages.forEach((page) => {
        if (page.isActive) {
          data = {
            extension: reportType,
            state: reportBookmark.state,
            page: page.name,
            pageDisplayName: page.displayName,
          };
        }
      });
      try {
        const res = await API.exportPowerBIReport(data);
        toast.success(t("hbh.powerbi.export.confirm.success"));
        return res.data;
      } catch (err) {
        handleAddAxiosErrorNotification(err);
      }
    }
  };

  return (
    <>
      <PowerBIEmbed
        embedConfig={{
          type: "report",
          id: embedMetadata.reportId,
          embedUrl: embedMetadata.embedUrl,
          accessToken: embedMetadata.accessToken,
          tokenType: models.TokenType.Embed,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: true,
          },
        }}
        cssClassName={
          isDialog ? "analytics-report-dialog-class" : "analytics-report-class"
        }
        getEmbeddedComponent={(embeddedReport) => {
          setReport(embeddedReport as Report);
        }}
      />
    </>
  );
};
