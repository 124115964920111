import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Form, Formik } from "formik";
import { devices } from "library/constants";
import { ECheckedValue, IBenefitCategory } from "library/types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateBenefitCategory } from "state/queries/useCreateBenefitCategory";
import { useUpdateBenefitCategory } from "state/queries/useUpdateBenefitCategory";
import styled from "styled-components";
import * as yup from "yup";
import { BenefitFormItems } from "./BenefitFormItems";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  benefitCategory?: IBenefitCategory;
  countryCode: number;
  clientInternalNumberGOS?: number;
  benefitCategoryParentTag?: number | null;
  rootCountryCode?: boolean;
};

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "860px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingTop: "10px",
    "@media (max-width: 600px)": {
      flexDirection: "row",
    },
  },
});

export const BenefitCategoryDialog = ({
  open,
  setOpen,
  benefitCategory,
  countryCode,
  clientInternalNumberGOS = 0,
  rootCountryCode,
}: Props) => {
  const { t } = useTranslation();

  const { mutate: onCreateBenefitCategory, isLoading: createInProgress } =
    useCreateBenefitCategory();

  const { mutate: onUpdateBenefitCategory, isLoading: updateInProgress } =
    useUpdateBenefitCategory();

  const title = benefitCategory
    ? t("hbh.editBenefitCategory.label", {
        category: benefitCategory.name,
      })
    : t("hbh.addBenefitCategory.label");

  const styles = useStyles();

  const initialValues = React.useMemo(() => {
    return {
      name: benefitCategory?.name || "",
      consentTypes: benefitCategory?.consentTypes,
      consentTypesInsurer: benefitCategory?.consentTypesInsurer,
      consentTypesCompany: benefitCategory?.consentTypesCompany,
      uploadDocuments: benefitCategory?.uploadDocuments
        ? ECheckedValue.Yes
        : ECheckedValue.No,
      consentsNeeded: benefitCategory?.consentsNeeded
        ? ECheckedValue.Yes
        : ECheckedValue.No,
      descriptionAsDocument: benefitCategory?.descriptionAsDocument
        ? ECheckedValue.Yes
        : ECheckedValue.No,
      enrollmentProcessNeeded: benefitCategory?.enrollmentProcessNeeded
        ? ECheckedValue.Yes
        : ECheckedValue.No,
      coverPhoto: benefitCategory?.backgroundPicturePath
        ? {
            name: benefitCategory?.backgroundPictureName,
            path: benefitCategory?.backgroundPicturePath,
            base64String: benefitCategory?.backgroundPictureContent,
            type: benefitCategory?.backgroundPictureMimeType,
          }
        : null,
      description: benefitCategory?.description || "",
      attachments: benefitCategory?.attachments || [],
    };
  }, [
    benefitCategory?.name,
    benefitCategory?.consentTypes,
    benefitCategory?.consentTypesInsurer,
    benefitCategory?.consentTypesCompany,
    benefitCategory?.uploadDocuments,
    benefitCategory?.consentsNeeded,
    benefitCategory?.descriptionAsDocument,
    benefitCategory?.enrollmentProcessNeeded,
    benefitCategory?.backgroundPicturePath,
    benefitCategory?.backgroundPictureName,
    benefitCategory?.backgroundPictureContent,
    benefitCategory?.backgroundPictureMimeType,
    benefitCategory?.description,
    benefitCategory?.attachments,
  ]);

  const handleSubmit = (values) => {
    const consentTypes = values.consentTypes.every(
      (element) => typeof element === "number"
    )
      ? values.consentTypes
      : values.consentTypes?.map((type) => type.value);
    const consentTypesInsurer = values.consentTypesInsurer.every(
      (element) => typeof element === "number"
    )
      ? values.consentTypesInsurer
      : values.consentTypesInsurer?.map((type) => type.value);
    const consentTypesCompany = values.consentTypesCompany.every(
      (element) => typeof element === "number"
    )
      ? values.consentTypesCompany
      : values.consentTypesCompany?.map((type) => type.value);

    if (benefitCategory?.benefitCategoryTag) {
      onUpdateBenefitCategory(
        {
          countryCode: Number(benefitCategory.countryCode),
          clientInternalNumberGOS: Number(
            benefitCategory.clientInternalNumberGos
          ),
          benefitCategoryTag: benefitCategory?.benefitCategoryTag,

          payload: {
            ...benefitCategory,

            name: values?.name || "",
            consentTypes,
            consentTypesInsurer,
            consentTypesCompany,
            uploadDocuments: values?.uploadDocuments === ECheckedValue.Yes,
            consentsNeeded: values?.consentsNeeded === ECheckedValue.Yes,
            descriptionAsDocument:
              values?.descriptionAsDocument === ECheckedValue.Yes,
            enrollmentProcessNeeded:
              values?.enrollmentProcessNeeded === ECheckedValue.Yes,

            description: values?.description || "",
            backgroundPictureContent: values.coverPhoto?.base64String,
            backgroundPictureName: values.coverPhoto?.name,
            backgroundPicturePath: values.coverPhoto?.path,
            backgroundPictureMimeType: values.coverPhoto?.type,
            ...(values.coverPhoto?.base64String && {
              backgroundPictureContent: values.coverPhoto?.base64String,
              backgroundPictureName: values.coverPhoto?.name,
              backgroundPicturePath: values.coverPhoto?.path,
              backgroundPictureMimeType: values.coverPhoto?.type,
            }),
            attachments: values?.attachments ? values.attachments : [],
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.benefitCategoryUpdatedSuccessfully.label"));
            setOpen(false);
          },
        }
      );
      return;
    }

    onCreateBenefitCategory(
      {
        countryCode,
        clientInternalNumberGOS,
        payload: {
          ...values,
          consentTypes,
          consentTypesInsurer,
          consentTypesCompany,
          consentsNeeded: values?.consentsNeeded === ECheckedValue.Yes,
          descriptionAsDocument:
            values?.descriptionAsDocument === ECheckedValue.Yes,
          enrollmentProcessNeeded:
            values?.enrollmentProcessNeeded === ECheckedValue.Yes,
          uploadDocuments: values?.uploadDocuments === ECheckedValue.Yes,
          backgroundPictureName: values?.coverPhoto?.name,
          backgroundPictureContent: values?.coverPhoto?.base64String,
          backgroundPictureMimeType: values?.coverPhoto?.type,
          ...(!values?.coverPhoto?.base64String && {
            backgroundPicturePath: values?.coverPhoto?.path,
          }),
        },
      },
      {
        onSuccess() {
          toast.success(t("hbh.benefitCategoryAddedSuccessfully.label"));
          setOpen(false);
        },
        onError() {
          toast.error(t("greco.error"));
        },
      }
    );
  };

  const validationSchema = yup.object().shape({
    name: yup.string().min(2).max(128).required(),
    description: yup.string().min(2).max(4000).required(),
    uploadDocuments: yup.mixed().required(),
    consentsNeeded: yup.mixed().required(),
    consentTypes: yup.array().min(1).required(),
    consentTypesInsurer: yup.array().min(1).required(),
    consentTypesCompany: yup.array().min(1).required(),
    descriptionAsDocument: yup.mixed().required(),
    enrollmentProcessNeeded: yup.mixed().required(),
  });

  const isLoading = createInProgress || updateInProgress;

  return (
    <>
      <Dialog
        modalType="alert"
        open={open}
        onOpenChange={(event, data) => setOpen(data.open)}
      >
        <StyledDialogSurface
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <>
            {isLoading && <AppSpinner />}

            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values, actions) => {
                handleSubmit(values);
                actions.setSubmitting(false);
              }}
            >
              {({ errors }) => {
                const hasErrors = !!Object.keys(errors)?.length;
                return (
                  <Form>
                    <DialogBody className={styles.content}>
                      <DialogTitle
                        action={
                          <DialogTrigger action="close">
                            <Button
                              appearance="subtle"
                              aria-label="close"
                              icon={<Dismiss24Regular />}
                            />
                          </DialogTrigger>
                        }
                      >
                        {title}
                      </DialogTitle>
                      <DialogContent className={styles.content}>
                        <BenefitFormItems
                          benefitCategory={benefitCategory}
                          countryCode={countryCode}
                          clientInternalNumberGOS={clientInternalNumberGOS}
                          rootCountryCode={rootCountryCode}
                        />
                      </DialogContent>
                    </DialogBody>
                    <DialogActions
                      className={benefitCategory ? styles.actions : ""}
                    >
                      <PrimaryButton
                        disabled={isLoading || hasErrors}
                        style={{
                          paddingLeft: "25px",
                          paddingRight: "25px",
                          marginLeft: "auto",
                        }}
                      >
                        {t("greco.save")}
                      </PrimaryButton>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          </>
        </StyledDialogSurface>
      </Dialog>
    </>
  );
};

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  // width: 100%;
  // min-width: 100%;
  // max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 860px;
    min-width: 860px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;
