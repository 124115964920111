import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateAllImages } from "./useGetAllImages";

type Params = {
  ImagePath: number | string;
};
const deleteImage = async ({ ImagePath }: Params) => {
  const response = await api.patch(`/images`, {
    ImagePath,
  });
  return response.data as string;
};

export function useDeleteImage() {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateAllImages = useInvalidateAllImages();

  return useMutation(deleteImage, {
    mutationKey: MUTATION_KEYS.deleteAppImage,
    onSuccess: () => {
      invalidateAllImages();
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
