import SelectField from "components/controls/SelectField";
import { paidByOptions } from "library/constants";
import { EPaidBy } from "library/types";
import { IPackageRowItem } from "./shared";
import { useTranslation } from "react-i18next";

type Props = {
  item: IPackageRowItem;
  readOnly?: boolean;
};

export function UsingPercentageForPaidByCell({ item, readOnly }: Props) {
  const { t } = useTranslation();
  const value = {
    text: item.usingPercentagesForPaidBy
      ? t("hbh.yes.label")
      : t("hbh.no.label"),
    value: item.usingPercentagesForPaidBy ? EPaidBy.Percentage : EPaidBy.Amount,
  };

  if (readOnly) {
    return value.text;
  }

  return (
    <SelectField
      error={""}
      setFieldValue={(name, data) => {
        const isPercentageValue = data.value === EPaidBy.Percentage;

        const updatedItem = {
          ...item,
          usingPercentagesForPaidBy: isPercentageValue,
          isEdited: true,
        };
        item.updateState(updatedItem);
      }}
      value={value as any}
      label=""
      options={paidByOptions}
      name={"usingPercentagesForPaidBy"}
      noErrorSection
      noLabel
    />
  );
}
