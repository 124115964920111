import { Button } from "@fluentui/react-components";
import { FileCodeIcon, FileHTMLIcon } from "@fluentui/react-icons-mdl2";
import { ExcelLogoIcon } from "@fluentui/react-icons-mdl2-branded";
import { StyledTooltip } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { ICompanyAction } from "library/types";
import { getExtensionFromUrl, openLinkInNewTab } from "library/utils";
import { useTranslation } from "react-i18next";

type Props = {
  item: ICompanyAction;
};

export function ActionDocumentsCell({ item }: Props) {
  const { t } = useTranslation();
  const theme = useAppTheme();
  const actionDocumentType = getExtensionFromUrl(item.actionDocumentPath);

  const actionDocumentIcon = (() => {
    if (actionDocumentType === "json") {
      return (
        <FileCodeIcon
          style={{
            color: theme.palette.yellowDark,
          }}
        />
      );
    }
    if (actionDocumentType === "xlsx") {
      return (
        <ExcelLogoIcon
          style={{
            color: theme.palette.greenDark,
          }}
        />
      );
    }

    return <></>;
  })();

  return (
    <div>
      <StyledTooltip
        content={t("hbh.actionDocument.label")}
        relationship="label"
      >
        <Button
          icon={actionDocumentIcon}
          appearance="transparent"
          onClick={() => {
            openLinkInNewTab(item.actionDocumentPath || "");
          }}
        />
      </StyledTooltip>
      <StyledTooltip
        content={t("hbh.reportDocument.label")}
        relationship="label"
      >
        <Button
          onClick={() => {
            openLinkInNewTab(item.reportDocumentPath || "");
          }}
          icon={
            <FileHTMLIcon
              style={{
                color: theme.palette.orange,
              }}
            />
          }
          appearance="transparent"
        />
      </StyledTooltip>
    </div>
  );
}
