import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployee } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number;
  clientInternalNumberGos: number;
  benefitPackageTag: number;
  employeeEnrollmentTag: number;
  employeeEmail: string;
  approve: boolean;
  payload: {
    rowVersion: string;
  };
};

const updateEmployeeEnrollmentStatus = async ({
  countryCode,
  clientInternalNumberGos,
  employeeEmail,
  employeeEnrollmentTag,
  benefitPackageTag,
  approve,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/approveorreject/${countryCode}/${clientInternalNumberGos}/${benefitPackageTag}/${employeeEmail}/${employeeEnrollmentTag}/${approve}`,
    payload
  );

  return response.data as IEmployee;
};

export function useUpdateEmployeeEnrollmentStatus(refetch = true) {
  const queryClient = useQueryClient();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();

  return useMutation(updateEmployeeEnrollmentStatus, {
    mutationKey: MUTATION_KEYS.updateEmployeeEnrollmentStatus,
    onSuccess: () => {
      if (refetch) {
        invalidateCompanyEmployees();
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeDetails,
        });
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeEnrollmentsByEmployee,
        });
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeEnrollmentsByPackage,
        });
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.companyAction,
        });
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
