import {
  Avatar,
  Button,
  Image,
  Link,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Text,
} from "@fluentui/react-components";
import { ArrowUploadRegular, CameraAddRegular } from "@fluentui/react-icons";
import { ClearIcon } from "@fluentui/react-icons-mdl2";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { IFormattedFile, IRowItem } from "library/types";
import {
  extractFileNameFromUrl,
  formatFile,
  getExtensionFromUrl,
} from "library/utils";
import { ManageImagesDialog } from "pages/AdminPage/components/ManageImages/ManageImagesDialog";
import { IPackageRowItem } from "pages/CompanyPackagesPage/components/BenefitImplementationPackages/shared";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

type Props = {
  item: IRowItem | IPackageRowItem;
  disabled?: boolean;
};

export const CoverPhotoCell = ({ item, disabled }: Props) => {
  const theme = useAppTheme();
  const [showImg, setShowImg] = useState(false);
  const [selectImageOpen, setSelectImageOpen] = useState(false);

  const [imgPreview, setImgPreview] = useState("");
  const defaultValue = useMemo(() => {
    return item.backgroundPicturePath
      ? {
          name: item.backgroundPictureName || "",
          path: item.backgroundPicturePath || "",
          base64String: item.backgroundPictureContent || "",
          type: item.backgroundPictureMimeType || "",
        }
      : null;
  }, [
    item.backgroundPicturePath,
    item.backgroundPictureName,
    item.backgroundPictureContent,
    item.backgroundPictureMimeType,
  ]);

  const [selectedFile, setSelectedFile] = useState<IFormattedFile | null>(
    defaultValue
  );

  const handleSelectImageOpenState = useCallback((state) => {
    setSelectImageOpen(state);
  }, []);

  const handleFileChange = async (_, image) => {
    const fileName = extractFileNameFromUrl(image);
    const imageType = getExtensionFromUrl(image);
    const formattedFile = {
      name: fileName,
      base64String: "",
      path: image,
      type: imageType,
    };

    setImgPreview(image);

    setSelectedFile(formattedFile);
    const updatedItem = {
      ...item,
      coverPhoto: formattedFile,
      isEdited: true,
    };
    item.updateState(updatedItem);
    setSelectImageOpen(false);
  };

  const handleMouseEnter = () => {
    setShowImg(true);
  };
  const onMouseLeave = () => {
    setShowImg(false);
  };

  useEffect(() => {
    if (item?.coverPhoto?.name === item?.defaultState?.coverPhoto?.name) {
      setSelectedFile(defaultValue);
    }
  }, [item]);

  useEffect(() => {
    setSelectedFile(defaultValue);
    setImgPreview("");
  }, [defaultValue]);

  return (
    <>
      <Container>
        {selectedFile ? (
          <Flex
            wrap="nowrap"
            justify="center"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {selectedFile.path ? (
              <Popover open={showImg} positioning={"above-start"}>
                <PopoverTrigger>
                  <Button
                    disabled={disabled}
                    icon={
                      <Image
                        style={{
                          width: "30px",
                          height: "30px",
                          objectFit: "cover",
                        }}
                        shadow
                        src={selectedFile?.path}
                        alt="cover"
                      />
                    }
                    appearance="transparent"
                  />
                </PopoverTrigger>
                <PopoverSurface>
                  <Link href={selectedFile.path} target="_blank">
                    <img width={"100px"} src={selectedFile?.path} alt="cover" />
                  </Link>
                </PopoverSurface>
              </Popover>
            ) : (
              <Text>
                <Image
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                  shadow
                  src={imgPreview}
                  alt="cover"
                />
              </Text>
            )}
          </Flex>
        ) : (
          <Text
            style={{
              cursor: "pointer",
            }}
          >
            <Avatar
              name={item?.name}
              style={{
                borderRadius: "5px",
              }}
            />
          </Text>
        )}
        <Flex justify="flex-end">
          {selectedFile && (
            <Button
              size="small"
              appearance="transparent"
              disabled={disabled}
              icon={
                <ClearIcon
                  style={{
                    transform: "scale(0.6)",
                    color: theme.palette.redDark,
                  }}
                />
              }
              onClick={() => {
                const updatedItem = {
                  ...item,
                  coverPhoto: {
                    name: null,
                    base64String: null,
                    type: null,
                  },
                  isEdited: !!selectedFile,
                };
                setSelectedFile(null);

                item.updateState(updatedItem);
              }}
            />
          )}
          <Button
            size="small"
            appearance="transparent"
            disabled={disabled}
            icon={<CameraAddRegular />}
            onClick={() => {
              setSelectImageOpen(true);
            }}
          />
        </Flex>
      </Container>
      {selectImageOpen && (
        <ManageImagesDialog
          onSelectImage={handleFileChange}
          open={selectImageOpen}
          setOpen={handleSelectImageOpenState}
        />
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`;
