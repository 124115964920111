import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IGrecoClient } from "library/types";
import { UseQueryResult, useQuery } from "react-query";
import api from "service/api";

type Params = {
  letters?: string;
  enabled?: boolean;
};

export const getUsers = async ({ letters = "" }: Params) => {
  const response = await api.get(`/userssearch/${letters || ` `}`);
  return response.data;
};

export function useGetUsers({
  letters,
  enabled = true,
}: Params): UseQueryResult<IGrecoClient[]> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IGrecoClient[], Error>(
    [QUERY_KEYS.users, letters],
    () => getUsers({ letters }),
    {
      staleTime: 0,
      refetchOnMount: true,
      // cacheTime: 0,
      enabled: enabled,
      onError: (error: any) => {
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}
