import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { IEmployeeEnrollment } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  benefitPackageTag: number | string;
  payload: IEmployeeEnrollment;
  employeeEmail: string;
};

const createDependentEligibility = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeEmail,
  benefitPackageTag,
  payload,
}: Params) => {
  const response = await api.post(
    `/employeeenrollment/dependent/${countryCode}/${clientInternalNumberGOS}/${benefitPackageTag}/${employeeEmail}`,
    payload
  );
  return response.data as IEmployeeEnrollment;
};

export function useCreateDependentEligibility(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(createDependentEligibility, {
    mutationKey: MUTATION_KEYS.createDependentEligibility,
    onSuccess: (dependentEligibility: any) => {
      // console.log(dependentEligibility, "data here");
      // const previousEmployeeData = queryClient.getQueryData([
      //   QUERY_KEYS.companyEmployeeViews,
      //   dependentEligibility.countryCode,
      //   dependentEligibility.clientInternalNumberGos,
      // ]) as any[];

      // const updatedEmployeeData = previousEmployeeData?.map((employee: any) => {
      //   if (
      //     employee.employeeDependentTag ===
      //     dependentEligibility.employeeDependentTag
      //   ) {
      //     return {
      //       employee,
      //       eligibilities: [...employee.eligibilities, dependentEligibility],
      //     };
      //   }
      //   return employee;
      // });
      //optimistic update
      // queryClient.setQueryData(
      //   [
      //     QUERY_KEYS.companyEmployeeViews,
      //     dependentEligibility.countryCode,
      //     dependentEligibility.clientInternalNumberGos,
      //   ],
      //   updatedEmployeeData
      // );
      // return dependentEligibility;

      if (refetch) {
        invalidateCompanyEmployees();
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
