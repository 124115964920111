import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { ICompanyAction } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string | null;
  clientInternalNumberGOS: number | string;
  enabled?: boolean;
};

const getCompanyActionHistory = async ({
  countryCode,
  clientInternalNumberGOS,
}: Params) => {
  if (countryCode === null || clientInternalNumberGOS === null) return;
  const response = await api.get(
    `/company/actionhistory/${countryCode}/${clientInternalNumberGOS}`
  );
  return response.data;
};

export function useGetCompanyActionHistory({
  countryCode,
  clientInternalNumberGOS,
  enabled = true,
}: Params): UseQueryResult<ICompanyAction[]> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<ICompanyAction[], Error>(
    [QUERY_KEYS.companyAction, countryCode, clientInternalNumberGOS],
    () => getCompanyActionHistory({ countryCode, clientInternalNumberGOS }),
    {
      refetchOnMount: true,
      //staleTime: 0,

      // cacheTime: 0,
      enabled: enabled,
      onError: (error: any) => {
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}

export const useInvalidateCompanyAction = () => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({ queryKey: QUERY_KEYS.companyAction });
};
