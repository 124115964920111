import { Text } from "@fluentui/react-components";
import { CameraAddRegular, DeleteRegular } from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { AvatarWithPreview } from "components/AvatarWithPreview/AvatarWithPreview";
import { ButtonWithTooltip } from "components/ButtonWithTooltip/ButtonWithTooltip";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { extractFileNameFromUrl } from "library/utils";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDeleteImage } from "state/queries/useDeleteImage";
import styled from "styled-components";

type Props = {
  onSelectImage?: (event: any, image?: string) => void;

  image: string;
};

export function ImageItem({ image, onSelectImage }: Props) {
  const { t } = useTranslation();
  const theme = useAppTheme();

  const fileName = extractFileNameFromUrl(image);

  const [deleteImageOpen, setDeleteImageOpen] = useState(false);

  const toggleDeleteImageOpenState = useCallback(() => {
    setDeleteImageOpen((prev) => !prev);
  }, []);

  const { mutate: onDeleteImage, isLoading: deleteInProgress } =
    useDeleteImage();

  return (
    <>
      <Container>
        {deleteInProgress && <AppSpinner />}
        <Flex $align="flex-end">
          <AvatarWithPreview
            style={{
              display: "inline-block",
              border: `2px solid ${theme.palette.neutralPrimaryAlt}`,
            }}
            size={28}
            shape="square"
            name={image}
            imgSrc={image}
          />
          <Text weight="semibold"> {fileName}</Text>
        </Flex>
        {onSelectImage ? (
          <ButtonWithTooltip
            icon={<CameraAddRegular />}
            onClick={(e) => {
              onSelectImage(e, image);
            }}
            tooltipContent={t("hbh.selectImage.label")}
          />
        ) : (
          <ButtonWithTooltip
            onClick={() => {
              setDeleteImageOpen(true);
            }}
            disabled={deleteInProgress}
            tooltipContent={t("hbh.deleteImage.label")}
            icon={
              <DeleteRegular
                style={{
                  ...(deleteInProgress
                    ? {}
                    : {
                        color: theme.palette.redDark,
                      }),
                }}
              />
            }
          />
        )}
      </Container>
      {deleteImageOpen && (
        <ConfirmationDialog
          isOpen={deleteImageOpen}
          toggleOpen={toggleDeleteImageOpenState}
          onConfirm={() => {
            onDeleteImage(
              {
                ImagePath: image,
              },
              {
                onSuccess: () => {
                  toast.success(t("hbh.imageDeletedSuccessfully.label"));
                  toggleDeleteImageOpenState();
                },
                onSettled: () => {
                  toggleDeleteImageOpenState();
                },
              }
            );
          }}
          title={t("hbh.deleteImage.label")}
          confirmLabel={t("greco.delete")}
          description={t("greco.dialog.deleteTitle", {
            object: fileName,
          })}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  gap: 5px;
  align-items: flex-end;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
  border-radius: 4px;
  // &:nth-of-type(1) {
  //   border-top: 1px solid ${({ theme }) => theme.palette.neutralLight};
  // }
  &:hover {
    background-color: ${({ theme }) => theme.palette.neutralLighter};
  }
`;
