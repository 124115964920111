import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { Attachment } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number | string;
  employeeLoginEmailAddress: string;
  clientInternalNumberGOS: number | string;
  attachmentName: string;
  payload: Attachment;
};
const updateAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeLoginEmailAddress,
  attachmentName,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employee/attachment/${countryCode}/${clientInternalNumberGOS}/${employeeLoginEmailAddress}/${attachmentName}`,
    payload
  );
  return response.data as Attachment;
};
export function useUpdateEmployeeAttachment(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(updateAttachment, {
    mutationKey: MUTATION_KEYS.updateEmployeeAttachment,
    onSuccess: () => {
      if (refetch) {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.employeeDetails,
        });

        invalidateCompanyEmployees();
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
