import { Text, ToggleButton, Tooltip } from "@fluentui/react-components";
import { Grid20Regular, List20Filled } from "@fluentui/react-icons";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { useGetUserAppTargetSetting } from "hooks/useGetUserAppTargetSetting";
import { APP_ID, APP_SETTING_ID } from "library/constants";
import { EAppSettings, EBenefitTab } from "library/types";
import { useTranslation } from "react-i18next";
import { useAddAppSetting } from "state/queries/useAddAppSetting";
import { useUpdateAppSettings } from "state/queries/useUpdateAppSettings";
import styled from "styled-components";

// type Props = {
//   isListView?: boolean;
//   setSelectedTabAsList?: (tab) => void;
//   setSelectedTabAsGrid?: (tab) => void;
// };

export function ViewSelectionToggle() {
  const { t } = useTranslation();
  const theme = useAppTheme();

  const benefitViewModeAppSettings = useGetUserAppTargetSetting({
    targetSettingName: EAppSettings.benefitViewMode,
  });

  const { mutate: onAddAppSetting } = useAddAppSetting();
  const { mutate: onUpdateAppSetting } = useUpdateAppSettings();

  const isListView =
    benefitViewModeAppSettings?.userAppSettingValue === EBenefitTab.list;

  const handleViewChange = (viewMode: EBenefitTab) => {
    if (benefitViewModeAppSettings) {
      onUpdateAppSetting({
        payload: [
          {
            ...benefitViewModeAppSettings,
            userAppSettingValue: viewMode,
          },
        ] as any,
      });
    } else {
      onAddAppSetting({
        payload: [
          {
            applicationCodeId: APP_ID,
            userAppSettingValue: viewMode,
            appSettingCodeId: APP_SETTING_ID,
            userAppSettingName: EAppSettings.benefitViewMode,
            isDefault: false,
          },
        ],
      });
    }
  };

  return (
    <Flex
      gap={"10px"}
      direction="column"
      justify="flex-start"
      $align="flex-start"
    >
      <Text>{t("hbh.viewMode.label")}</Text>

      <Container>
        <Tooltip content={t("hbh.listView.label")} relationship="label">
          <ToggleButton
            appearance="subtle"
            checked={isListView}
            icon={
              <List20Filled
                style={{
                  color: theme.palette.themePrimary,
                }}
              />
            }
            // onClick={setSelectedTabAsList}
            onClick={() => {
              handleViewChange(EBenefitTab.list);
            }}
            style={{
              borderTopRightRadius: "0",
              borderBottomRightRadius: "0",
              padding: "0px 25px",
            }}
          ></ToggleButton>
        </Tooltip>
        <Tooltip content={t("hbh.gridView.label")} relationship="label">
          <ToggleButton
            appearance="subtle"
            checked={!isListView}
            icon={
              <Grid20Regular
                style={{
                  color: theme.palette.themePrimary,
                }}
              />
            }
            // onClick={setSelectedTabAsGrid}
            onClick={() => {
              handleViewChange(EBenefitTab.grid);
            }}
            style={{
              borderTopLeftRadius: "0",
              borderBottomLeftRadius: "0",
              padding: "0px 25px",
            }}
          ></ToggleButton>
        </Tooltip>
      </Container>
    </Flex>
  );
}

const Container = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  border-radius: 6px;
  width: max-content;
`;
