import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IUserSettings } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  appId: number;
  enabled?: boolean;
};

export const getUserAppSettings = async ({ appId }: Params) => {
  const response = await api.get(`/uad/appsettings/${appId}`);

  return response.data as IUserSettings[];
};

export function useGetUserAppSettings({
  appId,
  enabled = true,
}: Params): UseQueryResult<IUserSettings[]> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IUserSettings[], Error>(
    [QUERY_KEYS.userSettings, appId],
    () => getUserAppSettings({ appId }),
    {
      enabled: enabled,
      onError: (error: any) => {
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}

export const useInvalidateUserAppSettings = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.userSettings,
    });
};
