import { PageContent } from "components/styled";
import { columnNames } from "library/constants";
import { ECompanyTabView, IBenefitCategory } from "library/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Text } from "@fluentui/react-components";
import { Analytics } from "components/Analytics/Analytics";
import {
  defaultColumnSizingOptions,
  getColumnsDef,
} from "components/ManageBenefitCategories/IncludedCategoriesTable/columns";
import { ManageBenefitCategories } from "components/ManageBenefitCategories/ManageBenefitCategories";
import { SelectBenefitCategoriesDialog } from "components/SelectBenefitCategoriesDialog/SelectBenefitCategoriesDialog";
import { PageSkeleton } from "components/Skeletons/PageSkeleton";
import { Subheader } from "components/Subheader/Subheader";
import { useSelectedViewTab } from "hooks/useSelectedViewTab";
import { useAtom, useAtomValue } from "jotai";
import { useGetBenefitCategories } from "state/queries/useGetBenefitCategories";
import { selectedCompanyTabState } from "store";
import {
  categorySearchFilterValueState,
  filteredBenefitCategoriesState,
  selectedCompanyState,
  selectedCountryState,
} from "store/UICompanyPage";
import { CompanyProfilePageToolbar } from "./components/CompanyProfilePageToolbar/CompanyProfilePageToolbar";
import { useRoleType } from "hooks/useRoleType";

export function CompanyProfilePage() {
  const { t } = useTranslation();
  const selectedTab = useSelectedViewTab();

  const roles = useRoleType();
  const isReadOnlyUser = roles?.hasReadOnlyRole;

  const selectedCompanyPageTab = useAtomValue(selectedCompanyTabState);

  const selectedCompany = useAtomValue(selectedCompanyState);
  const selectedCountry = useAtomValue(selectedCountryState);

  const [filteredBenefitCategories, setFilteredBenefitCategories] = useAtom(
    filteredBenefitCategoriesState
  );

  const [searchFilterValue, setSearchFilterValue] = useAtom(
    categorySearchFilterValueState
  );

  const [selectedBenefitCategory, setSelectedBenefitCategory] =
    useState<IBenefitCategory | null>(null);

  const [selectBenefitCategoriesDialog, setSelectedBenefitCategoriesDialog] =
    useState(false);

  const {
    data: companyBenefitCategories,
    isFetched: companyBenefitCatFetched,
    isLoading: companyBenefitCategoriesLoading,
  } = useGetBenefitCategories({
    countryCode: selectedCountry?.value || 0,
    clientInternalNumberGOS: selectedCompany?.internalNumber || 0,
    enabled: !!selectedCountry && !!selectedCompany,
  });

  const benefitCategoryTableItems = useMemo(() => {
    return filteredBenefitCategories
      ?.map((category) => {
        return {
          ...category,
          coverPhoto: {
            name: category.backgroundPicturePath,
            base64String: category.backgroundPictureContent,
            type: category.backgroundPictureMimeType,
          },
          id: category.benefitCategoryTag,
          defaultState: {
            ...category,
            coverPhoto: {
              name: category.backgroundPicturePath,
              base64String: category.backgroundPictureContent,
              type: category.backgroundPictureMimeType,
            },
            id: category.benefitCategoryTag,
          },
        };
      })
      .sort(
        (a, b) => Number(a.benefitCategoryTag) - Number(b.benefitCategoryTag)
      );
  }, [filteredBenefitCategories]);

  const onSelectBenefitCategoryDialogChange = useCallback((state) => {
    setSelectedBenefitCategoriesDialog(state);
  }, []);

  const onBenefitCategoryRowClick = useCallback(
    (row) => {
      setSelectedBenefitCategory(row);
    },
    [selectedBenefitCategory]
  );

  const handleSearchFilterValue = useCallback(
    (value) => {
      setSearchFilterValue(value);
    },
    [setSearchFilterValue]
  );

  useEffect(() => {
    // setSearchFilterValue("");
  }, [selectedCompany, selectedCountry, setSearchFilterValue]);

  useEffect(() => {
    if (!companyBenefitCategories) return;
    setFilteredBenefitCategories(
      companyBenefitCategories?.benefitCategories?.filter((item) =>
        item?.name?.toLowerCase().includes(searchFilterValue)
      )
    );
  }, [
    companyBenefitCategories,
    searchFilterValue,
    setFilteredBenefitCategories,
    selectedTab,
    companyBenefitCategoriesLoading,
  ]);

  useEffect(() => {
    const noCategoriesForCountry =
      companyBenefitCatFetched &&
      !companyBenefitCategories?.benefitCategories?.length &&
      selectedCountry &&
      !companyBenefitCategoriesLoading;
    if (noCategoriesForCountry) {
      setSelectedBenefitCategoriesDialog(true);
    } else {
      setSelectedBenefitCategoriesDialog(false);
    }
  }, [
    companyBenefitCategoriesLoading,
    companyBenefitCatFetched,
    selectedCountry,
    companyBenefitCategories?.benefitCategories?.length,
  ]);

  const columnsDef = getColumnsDef({
    countryCode: selectedCountry?.value || 0,
    withIncludedBenefits: true,
    clientInternalNumberGOS: selectedCompany?.internalNumber || 0,
    hasSelection: true,
    withOpenCategory: true,
    isReadOnlyUser,
    t,
  });

  // useEffect(() => {
  //   return () => {
  //     setSelectedCountry(null);
  //     setSelectedCompany(null);
  //     setSearchFilterValue("");
  //   };
  // }, []); //remove selected country and company on page change

  const showSelectBenefitCategoriesDialog =
    selectBenefitCategoriesDialog &&
    selectedCountry &&
    selectedCompany &&
    !companyBenefitCategories?.benefitCategories?.length &&
    !companyBenefitCategoriesLoading;

  const showActionButtons =
    selectedCompany &&
    !selectBenefitCategoriesDialog &&
    companyBenefitCategories?.benefitCategories?.length &&
    !companyBenefitCategoriesLoading;

  const loading = companyBenefitCategoriesLoading;

  return (
    <>
      <Subheader
        isCompanyPage
        disabled={
          !selectedCompany ||
          !selectedCountry ||
          !companyBenefitCategories?.benefitCategories?.length
        }
        searchFilterValue={searchFilterValue}
        items={filteredBenefitCategories || []}
        setSearchFilterValue={handleSearchFilterValue}
      />
      {showActionButtons ? (
        <CompanyProfilePageToolbar company={companyBenefitCategories.client} />
      ) : null}

      {loading ? (
        <PageSkeleton hideSubheader />
      ) : (
        <>
          {selectedCompanyPageTab === ECompanyTabView.benefits && (
            <PageContent>
              {showSelectBenefitCategoriesDialog && (
                <SelectBenefitCategoriesDialog
                  open={selectBenefitCategoriesDialog}
                  setOpen={onSelectBenefitCategoryDialogChange}
                  country={selectedCountry}
                  clientInternalNumberGOS={selectedCompany.internalNumber}
                />
              )}
              {showActionButtons ? (
                <>
                  <TableWrap>
                    <Text
                      block
                      weight="semibold"
                      underline
                      size={500}
                      style={{
                        marginBottom: "20px",
                      }}
                    >
                      {t("hbh.benefitCategories.label")}
                    </Text>
                    <ManageBenefitCategories
                      countryCode={Number(selectedCountry?.value) || 0}
                      currencyCode={selectedCompany.currencyCodeId}
                      clientInternalNumberGOS={
                        selectedCompany?.internalNumber || 0
                      }
                      benefitCategories={filteredBenefitCategories || []}
                      benefitTableItems={benefitCategoryTableItems || []}
                      defaultColumnSizingOptions={defaultColumnSizingOptions}
                      columns={columnsDef}
                      columnNames={columnNames}
                      selectionMode={"single"}
                      onRowClick={onBenefitCategoryRowClick}
                      hasImplementationActions
                    />
                  </TableWrap>
                </>
              ) : null}
            </PageContent>
          )}
          {selectedCompany &&
            selectedCompanyPageTab === ECompanyTabView.analytics && (
              <Analytics
                countryId={selectedCountry ? selectedCountry.value + "" : null}
                clientIds={
                  selectedCompany ? selectedCompany.internalNumber + "" : null
                }
                isDialog={false}
              />
            )}
        </>
      )}
    </>
  );
}

const TableWrap = styled.div`
  table {
    .selected-row {
      background-color: ${({ theme }) => theme.palette.themeLight} !important;
    }
  }
`;
