import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { Attachment } from "library/types";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  employeeEmail: string;
  rowVersion: string;
};
const deleteEmployee = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeEmail,
  rowVersion,
}: Params) => {
  const response = await api.patch(
    `/employee/delete/${countryCode}/${clientInternalNumberGOS}/${employeeEmail}`,
    {
      rowVersion,
    }
  );
  return response.data as Attachment;
};

export function useDeleteEmployee(refetch = true) {
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(deleteEmployee, {
    mutationKey: MUTATION_KEYS.deleteEmployee,
    onSuccess: () => {
      if (refetch) {
        invalidateCompanyEmployees();
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
