import { Button } from "@fluentui/react-components";
import { ImageAddRegular } from "@fluentui/react-icons";
import { SaveIcon } from "@fluentui/react-icons-mdl2";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { UploadFile } from "components/BenefitCategoryDialog/UploadFile";
import { useAppTheme } from "hooks/useAppTheme";
import { IFormattedFile } from "library/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAddImage } from "state/queries/useAddImage";
import styled from "styled-components";

export function AddImage() {
  const { t } = useTranslation();
  const theme = useAppTheme();
  const [newAttach, setNewAttach] = useState<IFormattedFile | null>(null);

  const { mutate: onAddImage, isLoading: imageIsUploading } = useAddImage();

  return (
    <Container>
      {imageIsUploading && <AppSpinner />}
      <Wrap>
        <UploadFile
          addIcon={<ImageAddRegular />}
          defaultValue={newAttach}
          setFieldValue={(_, file) => {
            setNewAttach(file);
          }}
          fieldName=""
          label=""
        />

        <Button
          style={{
            padding: "5px 15px",
          }}
          disabled={!newAttach}
          icon={
            <SaveIcon
              style={{
                color: theme.palette.themePrimary,
                opacity: newAttach ? "1" : "0.5",
              }}
            />
          }
          // disabled={updateInProgress}
          appearance="outline"
          size="small"
          onClick={async (e) => {
            onAddImage(
              {
                payload: {
                  ImageContent: newAttach?.base64String || "",
                  ImageName: newAttach?.name || "",
                  ImageMimeType: newAttach?.type || "",
                },
              },
              {
                onSuccess: () => {
                  setNewAttach(null);
                  toast.success(t("hbh.imageUploadedSuccessfully.label"));
                },
              }
            );
            console.log(newAttach, "new attach here");
          }}
        />
      </Wrap>
    </Container>
  );
}

const Container = styled.div`
  margin: 10px 0 20px;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
  padding: 5px;
`;
