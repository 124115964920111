import SelectField from "components/controls/SelectField";
import { checkedOptions } from "library/constants";
import { ECheckedValue } from "library/types";
import { useTranslation } from "react-i18next";
import { IPackageRowItem } from "./shared";

type Props = {
  item: IPackageRowItem;
  fieldName: string;
  readOnly?: boolean;
};

export function SelectWithYesNoOptionsCell({
  item,
  fieldName,
  readOnly,
}: Props) {
  const { t } = useTranslation();
  const value = {
    text: item[fieldName] ? t("hbh.yes.label") : t("hbh.no.label"),
    value: item[fieldName] ? ECheckedValue.Yes : ECheckedValue.No,
  };

  if (readOnly) {
    return value.text;
  }

  return (
    <SelectField
      onClick={(e) => {
        e.stopPropagation();
      }}
      error={""}
      setFieldValue={(name, value) => {
        const isYesValue = value.value === ECheckedValue.Yes;

        const updatedItem = {
          ...item,
          isEdited: true,

          [fieldName]: isYesValue,
        };
        item.updateState(updatedItem);
      }}
      value={value as any}
      label=""
      options={checkedOptions}
      name={fieldName}
      noErrorSection
      noLabel
    />
  );
}
