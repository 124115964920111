import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { IAddImagePayload } from "library/types";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateAllImages } from "./useGetAllImages";

type Params = {
  payload: IAddImagePayload;
};

const addImage = async ({ payload }: Params) => {
  const response = await api.post(`/images`, payload);

  return response.data as string;
};

export function useAddImage(refetch = true) {
  const invalidateAllAppImages = useInvalidateAllImages();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(addImage, {
    mutationKey: MUTATION_KEYS.addUserSettings,
    onSuccess: () => {
      if (refetch) {
        invalidateAllAppImages();
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
