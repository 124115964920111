import { MultiSelectCombobox } from "components/controls/MultiSelectCombobox";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ETaxonomy, IRowItem } from "library/types";
import { useMemo, useState } from "react";

type Props = {
  item: IRowItem;
  fieldName: string;
  readOnly?: boolean;
};

export function TypeOfConsentCell({ item, fieldName, readOnly }: Props) {
  const consentOptions = useTaxonomyOptions(ETaxonomy.ConsentType);
  const [updatedValue, setUpdatedValue] = useState<number[] | null>(null);

  const defaultValue = useMemo(() => {
    return consentOptions.filter((option) => {
      return item[fieldName]?.includes(option.value);
    });
  }, [consentOptions, item[fieldName]]);

  if (readOnly) {
    return defaultValue?.text;
  }
  return (
    <MultiSelectCombobox
      onClick={(e) => {
        e.stopPropagation();
      }}
      key={item[fieldName]?.join(",")}
      error={""}
      onBlur={() => {
        if (!updatedValue) return;
        item.updateState({
          ...item,
          isEdited: true,

          [fieldName]: updatedValue,
        });
      }}
      setFieldValue={(name, value) => {
        const ids = value.map((v) => v.value);

        // item.updateState({
        //   ...item,
        //   consentTypes: ids as number[],
        // });
        setUpdatedValue(ids as number[]);
      }}
      value={defaultValue as any}
      // value={localState as any}

      label=""
      options={consentOptions}
      name={fieldName}
      noErrorSection
      noLabel
    />
  );
}
