import InputField from "components/controls/InputField";
import { IPackageRowItem } from "./shared";

type Props = {
  item: IPackageRowItem;
  fieldName: string;
  type?: "text" | "number";
  readOnly?: boolean;
};

export function EditableInputCell({
  item,
  fieldName,
  type = "text",
  readOnly,
}: Props) {
  const handleTableUpdate = (value) => {
    if (value || value === "") {
      const updatedItem = {
        ...item,
        [fieldName]: value,
        isEdited: true,
      };
      item.updateState(updatedItem);
    }
  };

  if (readOnly) {
    return item[fieldName];
  }

  return (
    <InputField
      label=""
      name={fieldName}
      type={type}
      noLabel
      noErrorSection
      value={item[fieldName]}
      setFieldValue={(_, value) => {
        handleTableUpdate(value);
      }}
      error={""}
    />
  );
}
