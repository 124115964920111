import DatePickerField from "components/controls/DatePickerField";
import { formatDate } from "date-fns";
import { IRowItem } from "library/types";

type Props = {
  item: IRowItem;
  fieldName: string;
  readOnly?: boolean;
};

export function DateCell({ item, fieldName, readOnly }: Props) {
  const handleTableUpdate = (value) => {
    if (value || value === null) {
      const updatedItem = {
        ...item,
        [fieldName]: value,
        isEdited: true,
      };
      item.updateState(updatedItem);
    }
  };
  if (readOnly) {
    return formatDate(new Date(item[fieldName]), "dd.MM.yyyy");
  }
  return (
    <DatePickerField
      disableInitSetValue
      label=""
      noErrorSection
      name={fieldName}
      value={item[fieldName] ? new Date(item[fieldName]) : null}
      setFieldValue={(_, value) => {
        handleTableUpdate(value);
      }}
    />
  );
}
