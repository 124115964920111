import { TableCellLayout, createTableColumn } from "@fluentui/react-components";
import { ManageImplementationDocuments } from "components/ManageImplementationDocuments/ManageImplementationDocuments";
import { AddPackageCell } from "components/TableCells/AddPackageCell";
import { CarrierCell } from "components/TableCells/CarrierCell";
import CategoryNameCell from "components/TableCells/CategoryNameCell";
import { ConsentNeededCell } from "components/TableCells/ConsentNeededCell";
import { CoverPhotoCell } from "components/TableCells/CoverPhotoCell";
import { CurrencyCell } from "components/TableCells/CurrencyCell";
import { DateCell } from "components/TableCells/DateCell";
import { DescAsDocCell } from "components/TableCells/DescAsDocCell";
import DescCell from "components/TableCells/DescCell";
import { EnrollmentProcessNeeded } from "components/TableCells/EnrollmentProcessNeeded";
import { ImplementedBenefitActionsCell } from "components/TableCells/ImplementedBenefitActionsCell";
import { ImplementedBenefitNameCell } from "components/TableCells/ImplementedBenefitNameCell";
import { PolicyContractNumberCell } from "components/TableCells/PolicyContractNumberCell";
import { TypeOfConsentCellSingleSelect } from "components/TableCells/TypeOfConsentCellSingleSelect";
import { UploadDocumentsCell } from "components/TableCells/UploadDocumentsCell";
import { TFunction } from "i18next";
import { IBenefitCategory, IRowItem } from "library/types";
import styled from "styled-components";

type ColumnProps = {
  countryCode: string | number;
  clientInternalNumberGOS?: number;
  benefitCategoryTag?: number;
  withIncludedBenefits?: boolean;
  hasSelection?: boolean;
  benefitCategory?: IBenefitCategory;
  isReadOnlyUser?: boolean;
  t: TFunction;
};

type IHeaderItem =
  | {
      toggleAllRows: (e: any) => void;
      allRowsSelected: boolean;
      selectionMode: "single" | "multiselect";
      someRowsSelected: boolean;
      toggleAllKeydown: (e: any) => void;
    }
  | any;

export const getImplementedBenefitsColumnsDef = ({
  countryCode,
  clientInternalNumberGOS = 0,
  benefitCategoryTag,
  hasSelection,
  benefitCategory,
  isReadOnlyUser,
  t,
}: ColumnProps) => [
  // ...(hasSelection
  //   ? [
  //       createTableColumn({
  //         columnId: "select",
  //         renderHeaderCell: (item: IHeaderItem) => {
  //           if (item.selectionMode === "multiselect") {
  //             return (
  //               <TableSelectionCell
  //                 checked={
  //                   item ? true : item.someRowsSelected ? "mixed" : false
  //                 }
  //                 onClick={(e) => {
  //                   if (item.selectionMode === "multiselect") {
  //                     item.toggleAllRows(e);
  //                   }
  //                 }}
  //                 onKeyDown={item.toggleAllKeydown}
  //                 checkboxIndicator={{ "aria-label": "Select all rows " }}
  //               />
  //             );
  //           }
  //           if (item.selectionMode === "single") {
  //             return <TableSelectionCell type="radio" invisible />;
  //           }
  //           return <></>;
  //         },
  //         renderCell: (item: IRowItem) => {
  //           if (!item.selectionMode) return <></>;
  //           if (item.selectionMode === "multiselect")
  //             return (
  //               <TableSelectionCell
  //                 checked={item.selected}
  //                 checkboxIndicator={{ "aria-label": "Select row" }}
  //               />
  //             );
  //           if (item.selectionMode === "single") {
  //             return (
  //               <TableSelectionCell
  //                 checked={item.selected}
  //                 type="radio"
  //                 radioIndicator={{ "aria-label": "Select row" }}
  //               />
  //             );
  //           }
  //         },

  //         compare: (a, b) => {
  //           return (a?.benefitCategoryTag || 0) - (b?.benefitCategoryTag || 0);
  //         },
  //       }),
  //     ]
  //   : [
  //       createTableColumn({
  //         columnId: "id",
  //         renderHeaderCell: () => <>{columnNames.id}</>,
  //         renderCell: (item: IRowItem) => {
  //           return <TableCellLayout>{item.benefitCategoryTag}</TableCellLayout>;
  //         },

  //         compare: (a, b) => {
  //           return (a?.benefitCategoryTag || 0) - (b?.benefitCategoryTag || 0);
  //         },
  //       }),
  //     ]),
  createTableColumn({
    columnId: "coverPhoto",
    renderHeaderCell: () => <>{t("hbh.coverPicture.label")}</>,
    renderCell: (item: IRowItem) => {
      return <CoverPhotoCell item={item} disabled={isReadOnlyUser} />;
    },

    compare: (a, b) => {
      return (a?.coverPhoto?.name || "")?.localeCompare(
        b?.coverPhoto?.name || ""
      );
    },
  }),
  createTableColumn({
    columnId: "name",
    renderHeaderCell: () => <>{t("hbh.name.label")}</>,
    renderCell: (item: IRowItem) => {
      if (isReadOnlyUser) {
        return item.name;
      }
      return (
        <TableCellLayout>
          <ImplementedBenefitNameCell item={item} />
        </TableCellLayout>
      );
    },

    compare: (a, b) => {
      return (a?.name || "").localeCompare(b?.name || "");
    },
  }),
  createTableColumn({
    columnId: "benefitCategoryName",
    renderHeaderCell: () => <>{t("hbh.benefitCategory.label")}</>,
    renderCell: (item: IRowItem) => {
      if (isReadOnlyUser) {
        return item.benefitCategoryName;
      }
      return <CategoryNameCell item={item} />;
    },

    compare: (a, b) => {
      return (a?.benefitCategoryName || "").localeCompare(
        b?.benefitCategoryName || ""
      );
    },
  }),
  createTableColumn({
    columnId: "addPackage",
    renderHeaderCell: () => <>{t("hbh.addPackage.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <TableCellLayout truncate>
          <AddPackageCell item={item} disabled={isReadOnlyUser} />
        </TableCellLayout>
      );
    },
  }),
  createTableColumn({
    columnId: "carrierName",
    renderHeaderCell: () => <>{t("hbh.carrier.label")}</>,
    renderCell: (item: IRowItem) => {
      if (isReadOnlyUser) {
        return item?.carrierName;
      }
      return (
        <TableCellLayout truncate>
          <CarrierCell item={item} />
        </TableCellLayout>
      );
    },
    compare: (a, b) => {
      return (a?.carrierName || "").localeCompare(b?.carrierName || "");
    },
  }),
  createTableColumn({
    columnId: "packagesCount",
    renderHeaderCell: () => <>{t("hbh.packagesNumber.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <TableCellLayout truncate>
          {item?.packagesCount?.toString()}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn({
    columnId: "policyOrContractNumber",
    renderHeaderCell: () => <>{t("hbh.policyOrContractNumber.label")}</>,
    renderCell: (item: IRowItem) => {
      if (isReadOnlyUser) {
        return item?.policyOrContractNumber;
      }
      return (
        <TableCellLayout truncate>
          <PolicyContractNumberCell item={item} />
        </TableCellLayout>
      );
    },
    compare: (a, b) => {
      return (a?.policyOrContractNumber || "").localeCompare(
        b?.policyOrContractNumber || ""
      );
    },
  }),
  createTableColumn({
    columnId: "validFrom",
    renderHeaderCell: () => <>{t("hbh.validFrom.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <TableCellLayout truncate>
          <DateCell
            item={item}
            fieldName="validFrom"
            readOnly={isReadOnlyUser}
          />
        </TableCellLayout>
      );
    },
    compare: (a, b) => {
      return (a?.validFrom || "").localeCompare(b?.validFrom || "");
    },
  }),
  createTableColumn({
    columnId: "validTo",
    renderHeaderCell: () => <>{t("hbh.validTo.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <TableCellLayout truncate>
          <DateCell item={item} fieldName="validTo" readOnly={isReadOnlyUser} />
        </TableCellLayout>
      );
    },
    compare: (a, b) => {
      return (a?.validTo || "").localeCompare(b?.validTo || "");
    },
  }),
  createTableColumn({
    columnId: "manageDocuments",
    renderHeaderCell: () => <>{t("hbh.documents.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <ManageImplementationDocuments item={item} disabled={isReadOnlyUser} />
      );
    },

    compare: (a, b) => {
      return (a?.coverPhoto?.name || "")?.localeCompare(
        b?.coverPhoto?.name || ""
      );
    },
  }),

  createTableColumn({
    columnId: "uploadDocuments",
    renderHeaderCell: () => <>{t("hbh.uploadDocuments.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <SelectCellWrap>
          <UploadDocumentsCell item={item} readOnly={isReadOnlyUser} />
        </SelectCellWrap>
      );
    },
    compare: (a, b) => {
      return (a?.uploadDocuments ? 1 : 0) - (b?.uploadDocuments ? 1 : 0);
    },
  }),

  createTableColumn({
    columnId: "consentNeeded",
    renderHeaderCell: () => <>{t("hbh.consentNeeded.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <SelectCellWrap>
          <ConsentNeededCell item={item} readOnly={isReadOnlyUser} />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (a?.consentsNeeded ? 1 : 0) - (b?.consentsNeeded ? 1 : 0);
    },
  }),
  createTableColumn({
    columnId: "currencyCode",
    renderHeaderCell: () => <>{t("hbh.currency.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <SelectCellWrap>
          <CurrencyCell item={item} readOnly={isReadOnlyUser} />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (a?.consentsNeeded ? 1 : 0) - (b?.consentsNeeded ? 1 : 0);
    },
  }),
  createTableColumn({
    columnId: "consentTypes",
    renderHeaderCell: () => <>{t("hbh.typeOfConsent.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <SelectCellWrap>
          <TypeOfConsentCellSingleSelect
            item={item}
            readOnly={isReadOnlyUser}
            categoryOptions={benefitCategory?.consentTypes}
          />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (a?.consentTypes?.length || 0) - (b?.consentTypes?.length || 0);
    },
  }),
  createTableColumn({
    columnId: "descriptionAsDocument",
    renderHeaderCell: () => <>{t("hbh.descriptionAsDocument.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <SelectCellWrap>
          <DescAsDocCell item={item} readOnly={isReadOnlyUser} />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (
        (a?.descriptionAsDocument ? 1 : 0) - (b?.descriptionAsDocument ? 1 : 0)
      );
    },
  }),
  createTableColumn({
    columnId: "enrollmentProcessNeeded",
    renderHeaderCell: () => <>{t("hbh.enrollmentProcessNeeded.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <SelectCellWrap>
          <EnrollmentProcessNeeded item={item} readOnly={isReadOnlyUser} />
        </SelectCellWrap>
      );
    },

    compare: (a, b) => {
      return (
        (a?.enrollmentProcessNeeded ? 1 : 0) -
        (b?.enrollmentProcessNeeded ? 1 : 0)
      );
    },
  }),

  createTableColumn({
    columnId: "description",
    renderHeaderCell: () => <>{t("hbh.description.label")}</>,
    renderCell: (item: IRowItem) => {
      return (
        <InputCellWrap>
          <DescCell item={item} readOnly={isReadOnlyUser} />
        </InputCellWrap>
      );
    },

    compare: (a, b) => {
      return (a?.description || "").localeCompare(b?.description || "");
    },
  }),

  createTableColumn({
    columnId: "actionsCell",
    renderHeaderCell: () => (
      <div
        style={{
          textAlign: "center",
          width: "100%",
        }}
      >
        {t("hbh.actions.label")}
      </div>
    ),
    renderCell: (item: IRowItem) => {
      return (
        <ImplementedBenefitActionsCell
          disabled={isReadOnlyUser}
          item={item}
          countryCode={countryCode}
          clientInternalNumberGOS={clientInternalNumberGOS}
          benefitCategoryTag={benefitCategoryTag}
          benefitCategory={benefitCategory}
        />
      );
    },

    // compare: (a, b) => {
    //   return a.enrollmentProcessNeeded - b.enrollmentProcessNeeded;
    // },
  }),
];

export const defaultColumnSizingOptions = {
  id: {
    idealWidth: 30,
    minWidth: 30,
  },
  select: {
    idealWidth: 30,
    minWidth: 30,
  },
  benefitCategory: {
    idealWidth: 200,
    minWidth: 200,
  },
  name: {
    idealWidth: 200,
    minWidth: 200,
  },
  addPackage: {
    idealWidth: 130,
    minWidth: 130,
  },
  manageDocuments: {
    idealWidth: 260,
    minWidth: 260,
  },
  policyOrContractNumber: {
    idealWidth: 200,
    minWidth: 200,
  },
  currencyCode: {
    idealWidth: 200,
    minWidth: 200,
  },
  carrierName: {
    idealWidth: 200,
    minWidth: 200,
  },

  packagesCount: {
    idealWidth: 100,
    minWidth: 100,
  },
  validFrom: {
    idealWidth: 130,
    minWidth: 130,
  },
  validTo: {
    idealWidth: 130,
    minWidth: 130,
  },

  coverPhoto: {
    minWidth: 90,
    defaultWidth: 90,
  },
  uploadDocuments: {
    minWidth: 80,
  },
  consentNeeded: {
    minWidth: 80,
  },
  consentTypes: {
    minWidth: 280,
  },
  descriptionAsDocument: {
    minWidth: 165,
  },
  enrollmentProcessNeeded: {
    minWidth: 150,
  },

  description: {
    minWidth: 220,
  },

  implementBenefit: {
    minWidth: 200,
  },

  actionsCell: {
    minWidth: 120,
    idealWidth: 120,
    defaultWidth: 120,
  },
};

const SelectCellWrap = styled.div`
  .fui-Select__select,
  .fui-Select__select:focus,
  .fui-Select__select:focus-within {
    border-bottom: none;
  }

  .fui-Combobox,
  .fui-Combobox:focus {
    border-bottom: none;
  }
`;

const InputCellWrap = styled.div`
  .fui-Input,
  .fui-Input:focus {
    border-bottom: none;
  }
`;
