import { Card, Title1 } from "@fluentui/react-components";
import { ICompanyInfosInfo } from "library/types";
import FilterCurrency from "./FilterCurrency";
import FilterDate from "./FilterDate";
import FilterPackages from "./FilterPackages";
import FilterPaymentMethod from "./FilterPaymentMethod";
import FilterPaymentType from "./FilterPaymentType";
import { useTranslation } from "react-i18next";
import FilterPeriod from "./FilterPeriod";
import styled from "styled-components";
import { devices } from "library/constants";

interface Props {
  companyInfo?: ICompanyInfosInfo;
  isLoading: boolean;
}
const FilterSection = ({ companyInfo, isLoading }: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Card style={{ padding: "20px" }}>
        <Title1>{t("hbh.filters.label")}</Title1>
        <FilterPeriod />
        <FilterPackages companyInfo={companyInfo} isLoading={isLoading} />
        <FilterCurrency companyInfo={companyInfo} />
        <FilterPaymentType />
        <FilterPaymentMethod />
        <FilterDate />
      </Card>
    </Container>
  );
};

export default FilterSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
