import SelectField from "components/controls/SelectField";
import { checkedOptions } from "library/constants";
import { ECheckedValue, IRowItem, SelectItem } from "library/types";
import { useTranslation } from "react-i18next";

type Props = {
  item: IRowItem;
  readOnly?: boolean;
};

export function ConsentNeededCell({ item, readOnly }: Props) {
  const { t } = useTranslation();
  const value = {
    text: item.consentsNeeded ? t("hbh.yes.label") : t("hbh.no.label"),
    value: item.consentsNeeded ? ECheckedValue.Yes : ECheckedValue.No,
  } as SelectItem;

  if (readOnly) {
    return value?.text;
  }

  return (
    <SelectField
      onClick={(e) => e.stopPropagation()}
      error={""}
      setFieldValue={(name, value) => {
        const isYesValue = value.value === ECheckedValue.Yes;
        const updatedItem = {
          ...item,
          consentsNeeded: isYesValue,
          isEdited: true,
        };
        item.updateState(updatedItem);
      }}
      value={value as any}
      label=""
      options={checkedOptions}
      name={"consentsNeeded"}
      noErrorSection
      noLabel
    />
  );
}
