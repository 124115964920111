import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IBenefitImplementation } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateBenefitCategories } from "./useGetBenefitCategories";

type Params = {
  countryCode: number | string;
  benefitCategoryTag: number | string;
  clientInternalNumberGOS: number | string;
  payload: IBenefitImplementation;
};

const createBenefitImplementation = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitCategoryTag,
  payload,
}: Params) => {
  const response = await api.post(
    `/benefitimplementation/${countryCode}/${clientInternalNumberGOS}/${benefitCategoryTag}`,
    payload
  );

  return response.data as IBenefitImplementation;
};

export function useCreateBenefitImplementation(
  refetch = true,
  refetchCategory = false
) {
  const queryClient = useQueryClient();
  const invalidateCategories = useInvalidateBenefitCategories();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(createBenefitImplementation, {
    mutationKey: MUTATION_KEYS.createBenefitImplementation,
    onSuccess: () => {
      if (refetch) {
        queryClient.refetchQueries({
          queryKey: QUERY_KEYS.benefitImplementations,
        });
      }
      if (refetchCategory) {
        invalidateCategories();
      }
      //queryClient.invalidateQueries({ queryKey: QUERY_KEYS.clientsByCountry });
      // queryClient.invalidateQueries({ queryKey: QUERY_KEYS.benefitCategories });
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
