import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IEmployeeEnrollment } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  benefitPackageTag: number;
  employeeLoginEmailAddress: string;
  employeeEnrollmentTag: number;
  enabled?: boolean;
};

const getEmployeeEnrollment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitPackageTag,
  employeeLoginEmailAddress,
  employeeEnrollmentTag,
}: Params) => {
  const response = await api.get(
    `/employeeenrollment/${countryCode}/${clientInternalNumberGOS}/${benefitPackageTag}/${employeeLoginEmailAddress}/${employeeEnrollmentTag}`
  );
  return response.data;
};

export function useGetEmployeeEnrollment({
  countryCode,
  clientInternalNumberGOS,
  employeeLoginEmailAddress,
  benefitPackageTag,
  employeeEnrollmentTag,
  enabled = true,
}: Params): UseQueryResult<IEmployeeEnrollment> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IEmployeeEnrollment, Error>(
    [
      QUERY_KEYS.employeeEnrollment,
      countryCode,
      clientInternalNumberGOS,
      employeeLoginEmailAddress,
      benefitPackageTag,
      employeeEnrollmentTag,
    ],
    () =>
      getEmployeeEnrollment({
        countryCode,
        clientInternalNumberGOS,
        employeeLoginEmailAddress,
        benefitPackageTag,
        employeeEnrollmentTag,
      }),
    {
      staleTime: 0,
      refetchOnMount: true,
      // cacheTime: 0,

      enabled: enabled,
      onError: (error: any) => {
        // errorHandler({
        //   label: "Error",
        //   type: notificationTypes.error,
        //   value: "Error while loading offer item data",
        //   errors: error?.message,
        //   fieldName: "",
        // });
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}

export const useInvalidateEmployeeEnrollment = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.employeeEnrollment,
    });
};
