import { ArrowExportRegular } from "@fluentui/react-icons";
import { ManageAttachmentsDialog } from "components/ManageAttachmentsDialog/ManageAttachmentsDialog";
import { ActionButton } from "components/styled";
import { useAtomValue } from "jotai";
import { Attachment, EmployeeRowItem, IFormattedFile } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateEmployeeAttachment } from "state/queries/useCreateEmployeeAttachment";
import { useDeleteEmployeeAttachment } from "state/queries/useDeleteEmployeeAttachment";
import { useUpdateEmployeeAttachment } from "state/queries/useUpdateEmployeeAttachment";
import { selectedCompanyState } from "store/UIHrPage";

type Props = {
  item: EmployeeRowItem;
};

export function ManageEmployeeAttachments({ item }: Props) {
  const { t } = useTranslation();
  const selectedCompany = useAtomValue(selectedCompanyState);

  const [open, setOpen] = useState(false);

  const onDialogOpenChange = useCallback((state) => {
    setOpen(state);
  }, []);

  const employeeAttachmentsNumber = item?.attachments?.length;

  const { mutate: onCreateAttachment, isLoading: createAttachmentInProgress } =
    useCreateEmployeeAttachment();

  const { mutate: onUpdateAttachment, isLoading: updateInProgress } =
    useUpdateEmployeeAttachment();

  const { mutate: onDeleteAttachment, isLoading: deleteInProgress } =
    useDeleteEmployeeAttachment();

  const handleUpdateAttachment = useCallback(
    ({
      attachment,
      newValues,
    }: {
      attachment: Attachment;
      newValues: {
        attachmentName: string;
        attachmentSummary: string;
        attachmentAbstract: string;
        isHiddenForEmployee: boolean;
      };
    }) => {
      onUpdateAttachment(
        {
          attachmentName: attachment.attachmentName || "",
          clientInternalNumberGOS:
            selectedCompany?.clientInternalNumberGos || 0,
          countryCode: selectedCompany?.countryCode || 0,
          employeeLoginEmailAddress: item.employeeLoginEmailAddress || "",
          payload: {
            ...attachment,
            attachmentName: newValues?.attachmentName,
            attachmentSummary: newValues?.attachmentSummary,
            attachmentAbstract: newValues?.attachmentAbstract,
            isHiddenForEmployee: newValues?.isHiddenForEmployee,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentUpdatedSuccessfully.label"));
          },
        }
      );
    },
    []
  );

  const titleLabel = item?.attachments?.length
    ? t("hbh.manageDocuments.label")
    : t("hbh.addDocuments.label");

  const handleCreateAttachment = useCallback(
    (newAttach: IFormattedFile | null, isHiddenForEmployee?: boolean) => {
      onCreateAttachment(
        {
          clientInternalNumberGOS:
            selectedCompany?.clientInternalNumberGos || 0,
          countryCode: selectedCompany?.countryCode || 0,
          employeeLoginEmailAddress: item?.employeeLoginEmailAddress || "",
          payload: {
            attachmentName: newAttach?.name || "",
            attachmentContent: newAttach?.base64String || "",
            attachmentMimeType: newAttach?.type || "",
            isHiddenForEmployee,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentCreatedSuccessfully.label"));
          },
        }
      );
    },
    []
  );

  const handleDeleteAttachment = useCallback((attach: Attachment) => {
    onDeleteAttachment(
      {
        attachmentName: attach.attachmentName || "",
        clientInternalNumberGOS: selectedCompany?.clientInternalNumberGos || 0,
        countryCode: selectedCompany?.countryCode || 0,
        employeeLoginEmailAddress: item?.employeeLoginEmailAddress || "",
        rowVersion: attach.rowVersion || "",
      },
      {
        onSuccess: () => {
          toast.success(t("hbh.documentDeletedSuccessfully.label"));
        },
      }
    );
  }, []);

  return (
    <>
      <ActionButton
        icon={<ArrowExportRegular />}
        noBorder
        appearance="subtle"
        onClick={(e) => {
          setOpen(true);
        }}
      >
        {titleLabel}
        {employeeAttachmentsNumber
          ? ` (${employeeAttachmentsNumber} ${t("hbh.items.label")})`
          : ` (${t("hbh.noData.label")}) `}
      </ActionButton>
      {open && (
        <ManageAttachmentsDialog
          attachmentActionsState={{
            createAttachmentInProgress,
            onCreateAttachment: handleCreateAttachment,
            updateInProgress,
            onUpdateAttachment: handleUpdateAttachment,
            deleteInProgress,
            onDeleteAttachment: handleDeleteAttachment,
          }}
          item={item}
          open={open}
          setOpen={onDialogOpenChange}
        />
      )}
    </>
  );
}
