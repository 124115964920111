import { Button, Spinner } from "@fluentui/react-components";
import { DeleteIcon } from "@fluentui/react-icons-mdl2";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { Flex, StyledTooltip } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { useRoleType } from "hooks/useRoleType";
import { useAtomValue } from "jotai";
import { EmployeeRowItem, IEmployeeView } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDeleteEmployee } from "state/queries/useDeleteEmployee";
import { useDeleteEmployeeDependent } from "state/queries/useDeleteEmployeeDependent";
import { selectedCompanyState } from "store/UIHrPage";

type Props = {
  employeeData: EmployeeRowItem;
  dependentData?: IEmployeeView;
  closeDialog: () => void;
};

export function DeletePackageUser({
  employeeData,
  dependentData,
  closeDialog,
}: Props) {
  const isReadOnlyUser = useRoleType()?.hasReadOnlyRole;

  const { t } = useTranslation();
  const { palette } = useAppTheme();
  const selectedCompany = useAtomValue(selectedCompanyState);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const { mutate: onDeleteEmployee, isLoading: deleteInProgress } =
    useDeleteEmployee();

  const {
    mutate: onDeleteEmployeeDependent,
    isLoading: deleteDependentInProgress,
  } = useDeleteEmployeeDependent();

  const handleDelete = useCallback(() => {
    if (dependentData) {
      onDeleteEmployeeDependent(
        {
          countryCode: Number(selectedCompany?.countryCode),
          clientInternalNumberGOS: Number(
            selectedCompany?.clientInternalNumberGos
          ),
          employeeLoginEmailAddress:
            dependentData?.employeeLoginEmailAddress || "",
          employeeDependentTag: Number(dependentData?.employeeDependentTag),
          rowVersion: dependentData?.rowVersion || "",
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.dependentDeletedSuccessfully.label"));
            closeDialog();
          },
        }
      );

      return;
    }
    onDeleteEmployee(
      {
        countryCode: Number(selectedCompany?.countryCode),
        clientInternalNumberGOS: Number(
          selectedCompany?.clientInternalNumberGos
        ),
        employeeEmail: employeeData?.employeeLoginEmailAddress || "",
        rowVersion: employeeData?.rowVersion || "",
      },
      {
        onSuccess: () => {
          toast.success(t("hbh.employeeDeletedSuccessfully.label"));
          closeDialog();
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dependentData,
    employeeData,
    onDeleteEmployee,
    onDeleteEmployeeDependent,
    selectedCompany,
    selectedCompany,
  ]);

  const toggleDeleteConfirmationState = useCallback(() => {
    setDeleteConfirmationOpen((prev) => !prev);
  }, []);

  const isLoading = deleteInProgress || deleteDependentInProgress;

  const packageUser = dependentData || employeeData;

  const disableDelete = isLoading || employeeData?.disabled || isReadOnlyUser;
  return (
    <>
      {isLoading ? (
        <>
          <Spinner size="extra-small" />
          <AppSpinner />
        </>
      ) : (
        <Flex width={"100%"} justify="center">
          <StyledTooltip
            relationship="label"
            content={{
              children: `${
                dependentData
                  ? t("hbh.deleteDependent.label")
                  : t("hbh.deleteEmployee.label")
              }`,
            }}
          >
            <Button
              icon={
                <DeleteIcon
                  style={{
                    ...(disableDelete ? {} : { color: palette.redDark }),
                  }}
                />
              }
              disabled={disableDelete}
              appearance="transparent"
              size="small"
              onClick={() => {
                setDeleteConfirmationOpen(true);
              }}
            />
          </StyledTooltip>
        </Flex>
      )}
      {deleteConfirmationOpen && (
        <ConfirmationDialog
          isOpen={deleteConfirmationOpen}
          toggleOpen={toggleDeleteConfirmationState}
          onConfirm={handleDelete}
          description={t("greco.dialog.deleteTitle", {
            object: `${packageUser?.firstName} ${packageUser?.lastName}`,
          })}
          confirmLabel={t("greco.delete")}
          title={`${t("hbh.deleteEmployee.label")} ${packageUser?.firstName} ${
            packageUser?.lastName
          }`}
        />
      )}
    </>
  );
}
