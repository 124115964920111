import {
  Button,
  Dialog,
  DialogBody,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { SearchBoxField } from "components/controls/SearchBoxField";
import { Flex } from "components/styled";
import { devices } from "library/constants";
import { extractFileNameFromUrl } from "library/utils";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetAllImages } from "state/queries/useGetAllImages";
import styled from "styled-components";
import { AddImage } from "./AddImage";
import { ImageItem } from "./ImageItem";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSelectImage?: (event: any, image?: string) => void;
};

export const ManageImagesDialog = ({ open, setOpen, onSelectImage }: Props) => {
  const { t } = useTranslation();
  const { data: allImages, isLoading: loadingImages } = useGetAllImages({
    enabled: true,
  });
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = useCallback((value) => {
    setSearchValue(value.toLowerCase());
  }, []);

  if (!allImages || loadingImages) return <AppSpinner />;

  return (
    <Dialog
      modalType="alert"
      open={open}
      onOpenChange={(event, data) => setOpen(data.open)}
    >
      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogBody>
          <DialogTitle
            action={
              <DialogTrigger action="close">
                <Button
                  appearance="subtle"
                  aria-label="close"
                  icon={<Dismiss24Regular />}
                />
              </DialogTrigger>
            }
          >
            <Flex gap={"10px"}>
              {t("hbh.manageImages.label")}
              <SearchBoxField
                style={{
                  // ...(!showSearchFilter && { display: "none" }),
                  height: "fit-content",
                }}
                disabled={loadingImages}
                value={searchValue}
                appearance="outline"
                placeholder={t("greco.form.searchPlaceholder")}
                onSearch={handleSearch}
                width={"300px"}
              />
            </Flex>
          </DialogTitle>
        </DialogBody>

        {onSelectImage ? null : <AddImage />}

        <ListContainer>
          {allImages
            .filter((image) => {
              const imageName = extractFileNameFromUrl(image);
              return imageName.toLowerCase().includes(searchValue);
            })
            .map((img) => {
              return (
                <ImageItem
                  key={img}
                  image={img}
                  onSelectImage={onSelectImage}
                />
              );
            })}
        </ListContainer>
      </StyledDialogSurface>
    </Dialog>
  );
};

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-height: 80vh;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 900px;
    min-width: 900px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
`;
