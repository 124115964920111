import DatePickerField from "components/controls/DatePickerField";
import { TextareaField } from "components/controls/TextareaField";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

const EmployeeCancelEnrollmentFormFields = () => {
  const { values, errors, setFieldValue } = useFormikContext<any>();
  const { t } = useTranslation();

  return (
    <>
      <Grid $gridColumns={3}>
        <DatePickerField
          error={
            typeof errors["dateOfCancellation"] === "string"
              ? errors["dateOfCancellation"]
              : undefined
          }
          name="dateOfCancellation"
          value={values["dateOfCancellation"] as Date}
          setFieldValue={setFieldValue}
          label={t("hbh.cancelationDate.label")}
          required={true}
        />
      </Grid>
      <Grid $gridColumns={1}>
        <TextareaField
          label={t("hbh.cancelationReason.label")}
          name="reasonForCancellation"
          value={values["reasonForCancellation"]}
          setFieldValue={setFieldValue}
          error={
            typeof errors["reasonForCancellation"] === "string"
              ? errors["reasonForCancellation"]
              : undefined
          }
          rows={3}
          resize="vertical"
          required={true}
        />
      </Grid>
    </>
  );
};

export default EmployeeCancelEnrollmentFormFields;
