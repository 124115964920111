import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { IExcelReportResponse } from "library/types";
import { useMutation } from "react-query";
import api from "service/api";

type Props = {
  enabled?: boolean;
  countryCode: number;
  clientInternalNumberGos: number;
  payload: any;
};

export const generateEmployeeEnrollmentsReportByTimePeriod = async ({
  countryCode,
  clientInternalNumberGos,
  payload,
}: Props) => {
  const response = await api.post(
    `/employee/excelexport/${countryCode}/${clientInternalNumberGos}`,
    {
      ...payload,
    }
  );
  return response.data as IExcelReportResponse;
};

// export function useGenerateEmployeeEnrollmentsReportByTimePeriod2({
//   enabled = true,
//   countryCode,
//   clientInternalNumberGos,
//   payload,
// }): UseQueryResult<IExcelReportResponse> {
//   const { handleAddAxiosErrorNotification } = useNotificationHandler();

//   return useQuery<IExcelReportResponse, Error>(
//     [QUERY_KEYS.generateEmployeeEnrollmentsReportByTimePeriod],
//     () => {
//       return generateEmployeeEnrollmentsReportByTimePeriod({
//         countryCode,
//         clientInternalNumberGos,
//         payload,
//       });
//     },
//     {
//       enabled: enabled,
//       onError: (error: any) => {
//         handleAddAxiosErrorNotification(error);
//       },
//     }
//   );
// }

export function useGenerateEmployeeEnrollmentsReportByTimePeriod(
  refetch = true
) {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(generateEmployeeEnrollmentsReportByTimePeriod, {
    mutationKey: MUTATION_KEYS.generateEmployeeEnrollmentsReportByTimePeriod,
    onSuccess: () => {},
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
