import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { IEmployee } from "library/types";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  payload: IEmployee;
};

const createEmployee = async ({
  countryCode,
  clientInternalNumberGOS,
  payload,
}: Params) => {
  const response = await api.post(
    `/employee/${countryCode}/${clientInternalNumberGOS}`,
    payload
  );

  return response.data as IEmployee;
};

export function useCreateEmployee(refetch = true) {
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(createEmployee, {
    mutationKey: MUTATION_KEYS.createEmployee,
    onSuccess: () => {
      if (refetch) {
        invalidateCompanyEmployees();
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
