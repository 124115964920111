import { Switch, Text } from "@fluentui/react-components";
import { InfoRegular } from "@fluentui/react-icons";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { IBenefitPackageView, IEmployeeView } from "library/types";
import { calculateAge } from "library/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  packageUser?: IEmployeeView;
};

export function PackageAgeLimitInfo({ packageUser }: Props) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  const { values, setFieldValue } = useFormikContext<any>();

  const benefitPackage = values["package"] as IBenefitPackageView;

  const ageConditionPassed = useMemo(() => {
    const userAge = calculateAge(packageUser?.dateOfBirth || "");
    const conditionPassed = (() => {
      if (userAge || userAge === 0) {
        if (benefitPackage?.ageLimitLower && benefitPackage?.ageLimitUpper) {
          return (
            userAge >= benefitPackage.ageLimitLower &&
            userAge <= benefitPackage.ageLimitUpper
          );
        }
        if (benefitPackage?.ageLimitLower) {
          return userAge >= benefitPackage.ageLimitLower;
        }
        if (benefitPackage?.ageLimitUpper) {
          return userAge <= benefitPackage.ageLimitUpper;
        }
        return true;
      } else {
        return true;
      }
    })();
    return conditionPassed;
  }, [
    packageUser?.dateOfBirth,
    benefitPackage?.ageLimitLower,
    benefitPackage?.ageLimitUpper,
  ]);

  const onChange = useCallback(
    (ev) => {
      setChecked(ev.currentTarget.checked);
    },
    [setChecked]
  );

  useEffect(() => {
    setFieldValue("ageConditionPassed", ageConditionPassed);
    if (checked) {
      setFieldValue("ageConditionPassed", true);
    }
  }, [ageConditionPassed, checked]);

  if (ageConditionPassed) return null;

  return (
    <Container>
      <Info>
        <InfoRegular fontSize={20} />
        {t("hbh.ageLimitInfo.label", {
          userType: packageUser?.isDependent
            ? t("hbh.dependent.label")
            : t("hbh.employee.label"),
          userAge: calculateAge(packageUser?.dateOfBirth || ""),
          ageLimitLower:
            benefitPackage?.ageLimitLower ?? t("hbh.notDefined.label"),
          ageLimitUpper:
            benefitPackage?.ageLimitUpper ?? t("hbh.notDefined.label"),
        })}
      </Info>
      <Switch checked={checked} onChange={onChange} label={""} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px;
`;

const Info = styled.div`
  display: flex;
  align-items: start;
  gap: 5px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.redDark};
`;
