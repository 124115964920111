import { Button } from "@fluentui/react-components";
import {
  Add16Filled,
  DataTrendingRegular,
  RibbonRegular,
} from "@fluentui/react-icons";
import { BenefitCategoryDialog } from "components/BenefitCategoryDialog/BenefitCategoryDialog";
import {
  ActionButton,
  StyledTab,
  StyledTabList,
  ToolbarContainer,
} from "components/styled";
import { useAtom, useAtomValue } from "jotai";
import { devices } from "library/constants";
import { ECompanyTabView, IClient } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectedCompanyTabState } from "store";
import {
  selectedCompanyState,
  selectedCountryState,
} from "store/UICompanyPage";
import styled from "styled-components";
import { EditClient } from "../EditClient/EditClient";
import { useRoleType } from "hooks/useRoleType";

type Props = {
  company: IClient;
};
export function CompanyProfilePageToolbar({ company }: Props) {
  const { t } = useTranslation();
  const [addBenefitCategoryOpen, setAddBenefitCategoryOpen] = useState(false);
  const [selectedCompanyPageTab, setSelectedCompanyPageTab] = useAtom(
    selectedCompanyTabState
  );
  const isReadOnlyUser = useRoleType()?.hasReadOnlyRole;

  const selectedCountry = useAtomValue(selectedCountryState);
  const selectedCompany = useAtomValue(selectedCompanyState);

  const handleAddBenefitCategoryDialogState = useCallback((state) => {
    setAddBenefitCategoryOpen(state);
  }, []);

  return (
    <ToolbarContainer $mobileTop="126px">
      <LeftItems>
        <StyledTabList
          selectedValue={selectedCompanyPageTab}
          onTabSelect={(e, data) => {
            setSelectedCompanyPageTab(data.value as ECompanyTabView);
          }}
        >
          <StyledTab icon={<RibbonRegular />} value={ECompanyTabView.benefits}>
            {t("hbh.benefits.label")}
          </StyledTab>
          <StyledTab
            icon={<DataTrendingRegular />}
            value={ECompanyTabView.analytics}
          >
            {t("hbh.analytics.label")}
          </StyledTab>
        </StyledTabList>
      </LeftItems>
      <RightItems>
        <EditClient client={company} />
        <ActionButton
          noBorder
          disabled={isReadOnlyUser}
          appearance="subtle"
          icon={<Add16Filled />}
          onClick={() => {
            setAddBenefitCategoryOpen(true);
          }}
        >
          {t("hbh.addBenefitCategory.label")}
        </ActionButton>
      </RightItems>
      {addBenefitCategoryOpen && (
        <BenefitCategoryDialog
          countryCode={Number(selectedCountry?.value) || 0}
          open={addBenefitCategoryOpen}
          setOpen={handleAddBenefitCategoryDialogState}
          benefitCategoryParentTag={Number(selectedCountry?.value)}
          rootCountryCode={false}
          clientInternalNumberGOS={selectedCompany?.internalNumber}
        />
      )}
    </ToolbarContainer>
  );
}

const LeftItems = styled.div`
  display: flex;
  align-items: center;
`;
const RightItems = styled.div`
  display: flex;
  align-items: center;
`;
