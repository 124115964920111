import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { PeopleAdd32Regular } from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import DropzoneComponent from "components/DropzoneComponent/DropzoneComponent";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { ActionButton } from "components/styled";
import { useRoleType } from "hooks/useRoleType";
import { useAtomValue } from "jotai";
import { IFileRequest } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useImportEmployeesFromExcel } from "state/queries/useImportEmployeesFromExcel";
import { selectedCompanyState } from "store/UIHrPage";

export function ImportEmployeeTemplate() {
  const { t } = useTranslation();
  const selectedCompany = useAtomValue(selectedCompanyState);
  const isReadOnlyUser = useRoleType()?.hasReadOnlyRole;

  const [open, setOpen] = useState(false);
  const [employeeTemplate, setEmployeeTemplate] = useState<IFileRequest | null>(
    null
  );

  const { mutate: onImportEmployeeTemplate, isLoading: importInProgress } =
    useImportEmployeesFromExcel();

  const handleSetEmployeeTemplate = useCallback((file: IFileRequest) => {
    setEmployeeTemplate(file);
  }, []);
  return (
    <>
      <ActionButton
        noBorder
        appearance="subtle"
        disabled={isReadOnlyUser}
        icon={<PeopleAdd32Regular />}
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("hbh.importEmployeeTemplate.label")}
      </ActionButton>
      {open && (
        <Dialog modalType="alert" open={open}>
          <DialogSurface>
            {importInProgress && <AppSpinner />}
            <DialogBody>
              <DialogTitle
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {t("hbh.importFromExcelTemplate.label")}
              </DialogTitle>
              <DialogContent>
                <DropzoneComponent setFile={handleSetEmployeeTemplate} />
              </DialogContent>
              <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    appearance="secondary"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    {t("greco.cancel")}
                  </Button>
                </DialogTrigger>
                <PrimaryButton
                  disabled={importInProgress}
                  onClick={() => {
                    if (!employeeTemplate) return;
                    onImportEmployeeTemplate(
                      {
                        clientInternalNumberGOS:
                          Number(selectedCompany?.clientInternalNumberGos) || 0,
                        countryCode: Number(selectedCompany?.countryCode) || 0,
                        payload: employeeTemplate,
                      },
                      {
                        onSuccess: () => {
                          setOpen(false);
                          toast.success(
                            t("hbh.employeeTemplateImported.label")
                          );
                        },
                      }
                    );
                  }}
                >
                  {t("hbh.import.label")}
                </PrimaryButton>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}
    </>
  );
}
