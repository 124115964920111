import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IBenefitPackage } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string | null;
  clientInternalNumberGOS: number | string;
  benefitCategoryTag: number | string;
  benefitImplementationTag: number | string;
  enabled?: boolean;
};

const getBenefitImplementationPackages = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitCategoryTag,
  benefitImplementationTag,
}: Params) => {
  if (
    countryCode === null ||
    clientInternalNumberGOS === null ||
    benefitCategoryTag === null ||
    benefitImplementationTag === null
  )
    return;

  const response = await api.get(
    `/benefitpackage/${countryCode}/${clientInternalNumberGOS}/${benefitCategoryTag}/${benefitImplementationTag}`
  );

  return response.data;
};

export function useGetBenefitImplementationPackages({
  countryCode,
  clientInternalNumberGOS,
  benefitCategoryTag,
  benefitImplementationTag,
  enabled = true,
}: Params): UseQueryResult<IBenefitPackage[]> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  return useQuery<IBenefitPackage[], Error>(
    [
      QUERY_KEYS.benefitImplementationPackages,
      countryCode,
      clientInternalNumberGOS,
      benefitCategoryTag,
      benefitImplementationTag,
    ],
    () =>
      getBenefitImplementationPackages({
        countryCode,
        clientInternalNumberGOS,
        benefitCategoryTag,
        benefitImplementationTag,
      }),
    {
      enabled: enabled,
      onError: (error: any) => {
        // handleAddNotification({
        //   id: genUUID(),
        //   text: error.message,
        //   type: "error",
        // });
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}

export const useInvalidateBenefitImplementationPackages = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.benefitImplementationPackages,
    });
};
