import { AxiosError } from "axios";
import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { IFileRequest } from "library/types";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateCompanyAction } from "./useGetCompanyActionHistory";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;

  payload: IFileRequest;
};

const updateClient = async ({
  countryCode,
  clientInternalNumberGOS,
  payload,
}: Params): Promise<string> => {
  const response = await api.post(
    `/company/template/${countryCode}/${clientInternalNumberGOS}`,

    {
      ...payload,
    }
  );

  return response.data;
};

export function useImportEmployeesFromExcel() {
  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const invalidateCompanyActions = useInvalidateCompanyAction();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(updateClient, {
    mutationKey: MUTATION_KEYS.importEmployeesFromExcel,
    onSuccess: () => {
      invalidateCompanyEmployees();
      // queryClient.refetchQueries({ queryKey: QUERY_KEYS.benefitCategories });
      invalidateCompanyActions();
    },
    onError: (error: AxiosError) => {
      handleAddAxiosErrorNotification(error);
    },
  });
}
