import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import {
  EApprovalStatus,
  ETaxonomy,
  IEmployeeEnrollmentBase,
} from "library/types";
import { useTranslation } from "react-i18next";

type Props = {
  employeeEnrollment?: IEmployeeEnrollmentBase;
};

export function useGetEnrollmentStatusData({ employeeEnrollment }: Props) {
  const { t } = useTranslation();

  const enrollmentStatusOptions = useTaxonomyOptions(ETaxonomy.ApprovalStatus);
  const approvalStatusCode = enrollmentStatusOptions.find(
    (status) => status.value === employeeEnrollment?.enrollmentApprovalStatus
  )?.code;

  const hasPendingStatus = approvalStatusCode === EApprovalStatus.PENDING;

  const hasEnrollmentDate = !!employeeEnrollment?.dateOfEnrollment;
  const hasCancellationDate = !!employeeEnrollment?.dateOfCancellation;

  const hasApproveActions = hasPendingStatus && hasEnrollmentDate;
  const hasRejectActions = hasPendingStatus && hasCancellationDate;

  const approveLabel = hasApproveActions
    ? t("hbh.approveEnrollment.label")
    : t("hbh.approveCancellation.label");
  const rejectLabel = hasRejectActions
    ? t("hbh.rejectCancellation.label")
    : t("hbh.rejectEnrollment.label");

  return {
    approvalStatusCode,
    hasApproveActions,
    hasRejectActions,
    approveLabel,
    rejectLabel,
  };
}
