import CheckboxField from "components/controls/CheckboxField";
import DatePickerField from "components/controls/DatePickerField";
import { FilteringCombobox } from "components/controls/FilteringCombobox";
import InputField from "components/controls/InputField";
import SelectField from "components/controls/SelectField";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ETaxonomy } from "library/types";
import { useTranslation } from "react-i18next";

type Props = {
  isDependent?: boolean;
};

export function BasicFields({ isDependent }: Props) {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<FormData>();

  const genderOptions = useTaxonomyOptions(ETaxonomy.Gender);
  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);
  const currencyOptions = useTaxonomyOptions(ETaxonomy.Currency);

  return (
    <Grid>
      <SelectField
        error={errors["genderCodeId"]}
        setFieldValue={setFieldValue}
        value={values["genderCodeId"]}
        label={t("hbh.gender.label")}
        options={genderOptions}
        name={"genderCodeId"}
        showEmptyOption
      />
      <InputField
        label={t("hbh.leadingTitle.label")}
        name="leadingTitle"
        value={values["leadingTitle"]}
        setFieldValue={setFieldValue}
        error={errors["leadingTitle"]}
      />
      <InputField
        label={t("hbh.trailingTitle.label")}
        name="trailingTitle"
        value={values["trailingTitle"]}
        setFieldValue={setFieldValue}
        error={errors["trailingTitle"]}
      />
      <InputField
        label={t("hbh.street.label")}
        name="street"
        value={values["street"]}
        setFieldValue={setFieldValue}
        error={errors["street"]}
      />
      <InputField
        label={t("hbh.buildingFloorDoor.label")}
        name="buildingFloorDoor"
        value={values["buildingFloorDoor"]}
        setFieldValue={setFieldValue}
        error={errors["buildingFloorDoor"]}
      />
      <InputField
        label={t("hbh.house.label")}
        name="house"
        value={values["house"]}
        setFieldValue={setFieldValue}
        error={errors["house"]}
      />
      <InputField
        label={t("hbh.city.label")}
        name="city"
        value={values["city"]}
        setFieldValue={setFieldValue}
        error={errors["city"]}
      />
      <InputField
        label={t("hbh.zipCode.label")}
        name="zipCode"
        value={values["zipCode"]}
        setFieldValue={setFieldValue}
        error={errors["zipCode"]}
      />
      <InputField
        label={t("hbh.birthplace.label")}
        name="birthplace"
        value={values["birthplace"]}
        setFieldValue={setFieldValue}
        error={errors["birthplace"]}
      />
      {!isDependent && (
        <InputField
          label={t("hbh.workplace.label")}
          name="workplace"
          value={values["workplace"]}
          setFieldValue={setFieldValue}
          error={errors["workplace"]}
        />
      )}
      <FilteringCombobox
        error={errors["citizenshipCodeId"]}
        setFieldValue={setFieldValue}
        value={values["citizenshipCodeId"]}
        label={t("hbh.citizenship.label")}
        options={countryOptions}
        name={"citizenshipCodeId"}
      />
      <InputField
        label={t("hbh.passportNumber.label")}
        name="passportNumber"
        value={values["passportNumber"]}
        setFieldValue={setFieldValue}
        error={errors["passportNumber"]}
      />

      <InputField
        label={t("hbh.personIdentificationNumber.label")}
        name="personIdentificationNumber"
        value={values["personIdentificationNumber"]}
        setFieldValue={setFieldValue}
        error={errors["personIdentificationNumber"]}
      />
      <InputField
        label={t("hbh.socialSecurityNumber.label")}
        name="socialSecurityNumber"
        value={values["socialSecurityNumber"]}
        setFieldValue={setFieldValue}
        error={errors["socialSecurityNumber"]}
      />

      {!isDependent && (
        <>
          <InputField
            label={t("hbh.orgUnitId.label")}
            name="orgUnitId"
            value={values["orgUnitId"]}
            setFieldValue={setFieldValue}
            error={errors["orgUnitId"]}
          />

          <DatePickerField
            name="dateOfExit"
            label={t("hbh.dateOfExit.label")}
            value={values["dateOfExit"]}
            setFieldValue={setFieldValue}
            error={errors["dateOfExit"]}
          />
          <InputField
            label={t("hbh.salary.label")}
            name="salary"
            value={values["salary"]}
            setFieldValue={setFieldValue}
            error={errors["salary"]}
          />
          <FilteringCombobox
            error={errors["currencyCode"]}
            setFieldValue={setFieldValue}
            value={values["currencyCode"]}
            label={t("hbh.currency.label")}
            options={currencyOptions}
            name={"currencyCode"}
          />
          <CheckboxField
            key={values["isInactive"]}
            name="isInactive"
            value={values["isInactive"]}
            setFieldValue={setFieldValue}
            label={t("hbh.isInactive.label")}
          />
        </>
      )}
    </Grid>
  );
}
