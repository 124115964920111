import { ERole } from "library/types";
import { useGetAuthorization } from "state/queries/useGetAuthorization";

export function useRoleType() {
  const { data: authorization } = useGetAuthorization();

  const roles = authorization?.roles;

  const hasHrRole = roles?.some(
    (role) =>
      role === ERole.HRCompanyAdmin || role === ERole.ReadonlyHRCompanyAdmin
  );

  const hasSuperAdminRole = roles?.some((role) => role === ERole.SuperAdmin);

  const hasAdminRole = roles?.some(
    (role) =>
      role === ERole.SuperAdmin ||
      role === ERole.CompanyAdmin ||
      role === ERole.GlobalAdmin ||
      role === ERole.CountryAdmin ||
      role === ERole.HRCompanyAdmin
  );

  return {
    hasHrRole,
    hasSuperAdminRole,
    hasAdminRole,
    hasReadOnlyRole: !hasAdminRole,

    //hasAdminRole: false,
    //hasReadOnlyRole: true, //for test readonly user
  };
}
