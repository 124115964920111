import { AxiosError } from "axios";
import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import {
  IEmployeeEnrollment,
  IEmployeeEnrollmentCalculatedDates,
} from "library/types";
import { useMutation } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  benefitPackageTag: number | string;
  employeeEmail: string;
  isAutoEnrollment: boolean;
  payload: IEmployeeEnrollment;
};

const suggestDates = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitPackageTag,
  employeeEmail,
  isAutoEnrollment,
  payload,
}: Params): Promise<IEmployeeEnrollmentCalculatedDates> => {
  const response = await api.patch(
    `/employeeenrollment/suggest/${countryCode}/${clientInternalNumberGOS}/${benefitPackageTag}/${employeeEmail}/${isAutoEnrollment}`,
    {
      ...payload,
    }
  );

  return response.data as IEmployeeEnrollmentCalculatedDates;
};

export function useSuggestDates() {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(suggestDates, {
    mutationKey: MUTATION_KEYS.suggestDates,

    onError: (error: AxiosError) => {
      handleAddAxiosErrorNotification(error);
    },
  });
}
