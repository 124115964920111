import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IBenefitImplementation } from "library/types";
import { genUUID } from "library/utils";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string | null;
  clientInternalNumberGOS: number | string;
  benefitCategoryTag: number | string;
  onlyActive?: boolean;
  enabled?: boolean;
};

const getBenefitImplementations = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitCategoryTag,
  onlyActive = true,
}: Params) => {
  if (
    countryCode === null ||
    clientInternalNumberGOS === null ||
    benefitCategoryTag === null
  )
    return;

  const response = await api.get(
    `/benefitimplementation/${countryCode}/${clientInternalNumberGOS}/${benefitCategoryTag}`,
    {
      params: {
        onlyActive,
      },
    }
  );

  return response.data;
};

export function useGetBenefitImplementations({
  countryCode,
  clientInternalNumberGOS,
  benefitCategoryTag,
  onlyActive,
  enabled = true,
}: Params): UseQueryResult<IBenefitImplementation[]> {
  const { handleAddNotification } = useNotificationHandler();
  return useQuery<IBenefitImplementation[], Error>(
    [
      QUERY_KEYS.benefitImplementations,
      countryCode,
      clientInternalNumberGOS,
      benefitCategoryTag,
      onlyActive,
    ],
    () =>
      getBenefitImplementations({
        countryCode,
        clientInternalNumberGOS,
        benefitCategoryTag,
        onlyActive,
      }),
    {
      enabled: enabled,
      // staleTime: 0,
      // refetchOnMount: true,
      onError: (error: any) => {
        handleAddNotification({
          id: genUUID(),
          text: error.message,
          type: "error",
        });
      },
    }
  );
}

export const useInvalidateBenefitImplementations = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.benefitImplementations,
    });
};
