import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IInsurer } from "library/types";
import { UseQueryResult, useQuery } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string | null;
  letters?: string;
  enabled?: boolean;
};

export const getInsurers = async ({ countryCode, letters = "" }: Params) => {
  if (countryCode === null) return;

  const response = await api.get(
    `/insurerssearch/${countryCode}/${letters || ` `}`
  );

  return response.data;
};

export function useGetClients({
  countryCode,
  letters,
  enabled = true,
}: Params): UseQueryResult<IInsurer[]> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IInsurer[], Error>(
    [QUERY_KEYS.insurers, countryCode, letters],
    () => getInsurers({ countryCode, letters }),
    {
      enabled: enabled,
      onError: (error: any) => {
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}
