import { Card, CardFooter, Tag } from "@fluentui/react-components";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  width: 100%;
  max-width: 100%;
  border-radius: 10px;

  padding: 0 !important;
  gap: 0;
`;

export const StyledCardFooter = styled(CardFooter)`
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background: ${({ theme }) => theme.palette.neutralLighterAlt};
  background-color: ${({ theme }) => theme.palette.themeLighterAlt};
`;

export const Desc = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 12px 5px;
  height: 40px;
`;

export const UploadImageWrap = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
  position: absolute;
  right: 10px;
  cursor: pointer;
  bottom: 5px;
  z-index: 10;
`;

export const AddWrap = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 35px !important;
  height: 35px !important;
  background-color: ${({ theme }) => theme.palette.themeLighterAlt};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 50%;
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 0;
  z-index: 10;
`;

export const StyledTag = styled(Tag)`
  width: auto;
  height: auto;
  background-color: ${({ theme }) => theme.palette.neutralLighterAlt};

  .fui-Tag {
    padding: 0;
  }

  button {
    min-width: auto;
    padding: 0px;
    margin: 0px;
  }
  .fui-Tag__media {
    padding-right: 0px;
    display: flex;
    justify-content: center;
  }
`;
