import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IBenefitPackage } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string;
  benefitCategoryTag: number | string;
  clientInternalNumberGOS: number | string;
  benefitImplementationTag: number | string;
  benefitPackageTag: number | string;
  payload: IBenefitPackage;
};
const updatePackage = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitCategoryTag,
  benefitImplementationTag,
  benefitPackageTag,
  payload,
}: Params) => {
  const response = await api.patch(
    `/benefitpackage/${countryCode}/${clientInternalNumberGOS}/${benefitCategoryTag}/${benefitImplementationTag}/${benefitPackageTag}`,
    payload
  );

  return response.data as IBenefitPackage;
};
export function useUpdateBenefitImplementationPackage(refetch = true) {
  const queryClient = useQueryClient();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(updatePackage, {
    mutationKey: MUTATION_KEYS.updateBenefitImplementationPackage,
    onSuccess: () => {
      if (refetch) {
        queryClient.refetchQueries({
          queryKey: QUERY_KEYS.benefitImplementationPackages,
        });
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
