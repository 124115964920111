import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Divider,
  makeStyles,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { format, formatDate } from "date-fns";
import { Form, Formik } from "formik";
import useGetAllCurrencyOptions from "hooks/useGetAllCurrencyOptions";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { devices } from "library/constants";
import {
  ECheckedValue,
  ETaxonomy,
  IBenefitCategory,
  IBenefitImplementation,
} from "library/types";
import { formatSelectFieldForPayload } from "library/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateBenefitImplementation } from "state/queries/useCreateBenefitImplementation";
import { useUpdateBenefitImplementation } from "state/queries/useUpdateBenefitImplementation";
import styled from "styled-components";
import * as yup from "yup";
import { ImplementBenefitFormItems } from "./ImplementBenefitFormItems";

type Props = {
  category?: IBenefitCategory;
  benefitImplementation?: IBenefitImplementation;
  open: boolean;
  setOpen: (open: boolean) => void;
  isAddMode?: boolean;
  refetchCategoryOnAdd?: boolean;
  currencyCode?: number;
};

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "900px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    paddingTop: "10px",
    "@media (max-width: 600px)": {
      flexDirection: "row",
    },
  },
});

export const ImplementBenefitDialog = ({
  category,
  open,
  setOpen,
  benefitImplementation,
  isAddMode,
  refetchCategoryOnAdd,
  currencyCode,
}: Props) => {
  const { t } = useTranslation();

  const title = isAddMode
    ? t("hbh.implementBenefitFor.label", {
        category: category?.name,
      })
    : `${benefitImplementation?.name}: Edit`;

  const styles = useStyles();
  const allCurrencyOptions = useGetAllCurrencyOptions();
  const consentOptions = useTaxonomyOptions(ETaxonomy.ConsentType);
  const currencyCodeOption = allCurrencyOptions?.find(
    (currency) =>
      currency.value ===
      ((isAddMode ? category : benefitImplementation)?.currencyCode ||
        currencyCode)
  );
  const {
    mutate: updateImplementation,
    isLoading: updateImplementationInProgress,
  } = useUpdateBenefitImplementation();

  const {
    mutate: onCreateBenefitImplementation,
    isLoading: createImplementationInProgress,
  } = useCreateBenefitImplementation(true, refetchCategoryOnAdd);

  const initialValues = useMemo(() => {
    const data = isAddMode ? category : benefitImplementation;

    const initialData = {
      name: data?.name || "",
      consentTypes: consentOptions.find((option) => {
        return data?.consentTypes?.includes(option?.value);
      }),
      consentTypesInsurer: consentOptions.find((option) => {
        return data?.consentTypesInsurer?.includes(option?.value);
      }),
      consentTypesCompany: consentOptions.find((option) => {
        return data?.consentTypesCompany?.includes(option?.value);
      }),
      currencyCode: allCurrencyOptions?.find(
        (currency) => currency.value === (data?.currencyCode || currencyCode)
      ),
      uploadDocuments: data?.uploadDocuments
        ? ECheckedValue.Yes
        : ECheckedValue.No,
      consentsNeeded: data?.consentsNeeded
        ? ECheckedValue.Yes
        : ECheckedValue.No,
      descriptionAsDocument: data?.descriptionAsDocument
        ? ECheckedValue.Yes
        : ECheckedValue.No,
      enrollmentProcessNeeded: data?.enrollmentProcessNeeded
        ? ECheckedValue.Yes
        : ECheckedValue.No,
      coverPhoto: data?.backgroundPicturePath
        ? {
            name: data?.backgroundPictureName,
            path: data?.backgroundPicturePath,
            base64String: data?.backgroundPictureContent,
            type: data?.backgroundPictureMimeType,
          }
        : null,
      description: data?.description || "",
      attachments: data?.attachments || [],
      ...(!isAddMode &&
        benefitImplementation && {
          carrierName: benefitImplementation?.carrierName,
          policyOrContractNumber: benefitImplementation?.policyOrContractNumber,
          validFrom: benefitImplementation?.validFrom,
          validTo: benefitImplementation?.validTo,
        }),
    };

    return initialData;
  }, [
    benefitImplementation,
    category,
    currencyCode,
    consentOptions,
    allCurrencyOptions,
    isAddMode,
  ]);

  const validationSchema = yup.object().shape({
    name: yup.string().min(2).max(128).required(),
    description: yup.string().min(2).max(4000).required(),
    uploadDocuments: yup.mixed().required(),
    currencyCode: yup.mixed().required(),
    consentsNeeded: yup.mixed().required(),
    // consentTypes: yup.mixed().required(),
    descriptionAsDocument: yup.mixed().required(),
    enrollmentProcessNeeded: yup.mixed().required(),
    validFrom: yup.mixed().required(),
    carrierName: yup.mixed().required(),
    validTo: yup.mixed().required(),
    policyOrContractNumber: yup.mixed().required(),
  });

  const handleSubmit = (values) => {
    // const consentTypes = values.consentTypes.every(
    //   (element) => typeof element === "number"
    // )
    //   ? values.consentTypes
    //   : values.consentTypes?.map((type) => type.value);
    const consentTypes = values?.consentTypes?.value
      ? [values.consentTypes.value]
      : [];
    const consentTypesInsurer = values?.consentTypesInsurer?.value
      ? [values.consentTypesInsurer.value]
      : [];
    const consentTypesCompany = values?.consentTypesCompany?.value
      ? [values.consentTypesCompany.value]
      : [];
    if (isAddMode) {
      onCreateBenefitImplementation(
        {
          countryCode: Number(category?.countryCode),
          clientInternalNumberGOS: Number(category?.clientInternalNumberGos),
          benefitCategoryTag: Number(category?.benefitCategoryTag),
          payload: {
            ...category,
            ...values,
            currencyCode: values?.currencyCode?.value
              ? values?.currencyCode.value
              : values.currencyCode,
            benefitCategoryParentTag: category?.benefitCategoryTag,
            consentTypes,
            consentTypesInsurer,
            consentTypesCompany,
            consentsNeeded: values?.consentsNeeded === ECheckedValue.Yes,
            descriptionAsDocument:
              values?.descriptionAsDocument === ECheckedValue.Yes,
            enrollmentProcessNeeded:
              values?.enrollmentProcessNeeded === ECheckedValue.Yes,
            uploadDocuments: values?.uploadDocuments === ECheckedValue.Yes,
            backgroundPictureName: values?.coverPhoto?.name,
            backgroundPictureContent: values?.coverPhoto?.base64String,
            backgroundPictureMimeType: values?.coverPhoto?.type,
            ...(!values?.coverPhoto?.base64String && {
              backgroundPicturePath: values?.coverPhoto?.path,
            }),
            attachments: values?.attachments || [],
            validFrom: formatDate(values.validFrom, "yyyy-MM-dd"),
            validTo: formatDate(values.validTo, "yyyy-MM-dd"),
          },
        },
        {
          onSuccess() {
            toast.success(t("hbh.implementationCreatedSuccessfully.label"));
            setOpen(false);
          },
          onError() {
            toast.error(t("greco.error"));
          },
        }
      );
      return;
    }
    updateImplementation(
      {
        countryCode: Number(benefitImplementation?.countryCode),
        clientInternalNumberGOS: Number(
          benefitImplementation?.clientInternalNumberGos
        ),
        benefitCategoryTag: Number(benefitImplementation?.benefitCategoryTag),
        benefitImplementationTag: Number(
          benefitImplementation?.benefitImplementationTag
        ),
        payload: {
          ...benefitImplementation,
          ...values,
          ...(values.coverPhoto?.base64String && {
            backgroundPictureContent: values.coverPhoto?.base64String,
            backgroundPictureName: values.coverPhoto?.name,
            backgroundPicturePath: values.coverPhoto?.path,
            backgroundPictureMimeType: values.coverPhoto?.type,
          }),
          ...(!values.coverPhoto?.name &&
            !values.coverPhoto?.path && {
              backgroundPictureContent: null,
              backgroundPictureName: null,
              backgroundPicturePath: null,
              backgroundPictureMimeType: null,
            }),
          consentTypes: [
            formatSelectFieldForPayload({
              values,
              field: "consentTypes",
            }),
          ],
          consentTypesInsurer: [
            formatSelectFieldForPayload({
              values,
              field: "consentTypesInsurer",
            }),
          ],
          consentTypesCompany: [
            formatSelectFieldForPayload({
              values,
              field: "consentTypesCompany",
            }),
          ],

          uploadDocuments: values?.uploadDocuments === ECheckedValue.Yes,
          consentsNeeded: values?.consentsNeeded === ECheckedValue.Yes,
          descriptionAsDocument:
            values?.descriptionAsDocument === ECheckedValue.Yes,
          enrollmentProcessNeeded:
            values?.enrollmentProcessNeeded === ECheckedValue.Yes,

          currencyCode: formatSelectFieldForPayload({
            values,
            field: "currencyCode",
          }),
          validFrom: format(values?.validFrom || "", "yyyy-MM-dd"),
          validTo: format(values?.validTo || "", "yyyy-MM-dd"),
        },
      },
      {
        onSuccess() {
          toast.success(t("hbh.implementationUpdatedSuccessfully.label"));
          setOpen(false);
        },
        onError() {
          toast.error(t("greco.error"));
        },
      }
    );
  };

  const isLoading =
    createImplementationInProgress || updateImplementationInProgress;
  return (
    <>
      <Dialog
        modalType="alert"
        open={open}
        onOpenChange={(event, data) => setOpen(data.open)}
      >
        <StyledDialogSurface
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {isLoading ? <AppSpinner /> : null}
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values, actions) => {
              handleSubmit(values);

              actions.setSubmitting(false);
            }}
          >
            {() => (
              <Form>
                <DialogBody className={styles.content}>
                  <DialogTitle
                    action={
                      <DialogTrigger action="close">
                        <Button
                          appearance="subtle"
                          aria-label="close"
                          icon={<Dismiss24Regular />}
                        />
                      </DialogTrigger>
                    }
                  >
                    {title}
                  </DialogTitle>
                  <DialogContent className={styles.content}>
                    <ImplementBenefitFormItems
                      benefitImplementation={benefitImplementation}
                      currencyCodeOption={currencyCodeOption}
                    />
                  </DialogContent>
                </DialogBody>
                <DialogActions className={isAddMode ? "" : styles.actions}>
                  <PrimaryButton
                    disabled={isLoading}
                    style={{
                      paddingLeft: "25px",
                      paddingRight: "25px",
                      marginLeft: "auto",
                    }}
                  >
                    {t("greco.save")}
                  </PrimaryButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </StyledDialogSurface>
      </Dialog>
    </>
  );
};

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 900px;
    min-width: 900px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;
