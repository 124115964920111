import { Divider } from "@fluentui/react-components";
import { AddAttachments } from "components/AddAttachments/AddAttachments";
import { UploadFile } from "components/BenefitCategoryDialog/UploadFile";
import CheckboxField from "components/controls/CheckboxField";
import InputField from "components/controls/InputField";
import SelectField from "components/controls/SelectField";
import { TextareaField } from "components/controls/TextareaField";
import { ManagePackageDocuments } from "components/ManagePackageDocuments/ManagePackageDocuments";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { ETaxonomy, IBenefitPackage } from "library/types";
import { IPackageRowItem } from "pages/CompanyPackagesPage/components/BenefitImplementationPackages/shared";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CancellationFields } from "./CancellationFields";
import { EligibleFields } from "./EligibleFields";
import { OffboardingFields } from "./OffboardingFields";
import { PriceFields } from "./PriceFields";

type Props = {
  packageData?: IBenefitPackage;
};

export function PackageFormItems({ packageData }: Props) {
  const { t } = useTranslation();
  const paymentTypeOptions = useTaxonomyOptions(ETaxonomy.PaymentType);

  const { values, setFieldValue, errors } = useFormikContext<FormData>();

  return (
    <Container>
      <Grid $gridColumns={4}>
        <InputField
          required
          label={t("greco.name")}
          name="name"
          value={values["name"]}
          setFieldValue={setFieldValue}
          error={errors["name"]}
        />
        <InputField
          required
          label={t("hbh.carrier.label")}
          name="carrierName"
          value={values["carrierName"]}
          setFieldValue={setFieldValue}
          error={errors["carrierName"]}
        />
        <PriceFields />
        <SelectField
          required
          error={errors["paymentType"]}
          showEmptyOption
          setFieldValue={setFieldValue}
          value={values["paymentType"]}
          label={t("hbh.paymentType.label")}
          options={paymentTypeOptions}
          name={"paymentType"}
        />
        <UploadFile
          isUploadAppImages
          key={values["coverPhoto"]?.path}
          setFieldValue={setFieldValue}
          fieldName="coverPhoto"
          defaultValue={values["coverPhoto"]}
          label={t("hbh.chooseCoverPicture.label")}
        />
        <InputField
          label={t("hbh.ageLimitLower.label")}
          name="ageLimitLower"
          type="number"
          value={values["ageLimitLower"]}
          setFieldValue={setFieldValue}
          error={errors["ageLimitLower"] || ""}
        />
        <InputField
          label={t("hbh.ageLimitUpper.label")}
          name="ageLimitUpper"
          type="number"
          value={values["ageLimitUpper"]}
          setFieldValue={setFieldValue}
          error={errors["ageLimitUpper"] || ""}
        />
      </Grid>
      <Grid $gridColumns={1}>
        <TextareaField
          label={t("hbh.description.label")}
          name="description"
          value={values["description"]}
          setFieldValue={setFieldValue}
          error={errors["description"]}
          rows={3}
          resize="vertical"
          required
        />
      </Grid>
      <Divider appearance="subtle" />
      <Grid $gridColumns={3}>
        <EligibleFields />
        <CancellationFields />
        <OffboardingFields />
      </Grid>
      <Divider appearance="subtle" />
      <Grid $gridColumns={4}>
        <CheckboxField
          name="isAutoEnrollment"
          required
          value={values["isAutoEnrollment"]}
          setFieldValue={setFieldValue}
          label={t("hbh.isAutoEnrollment.label")}
        />
        <CheckboxField
          name="isUpgradeable"
          required
          value={values["isUpgradeable"]}
          setFieldValue={setFieldValue}
          label={t("hbh.isUpgradeable.label")}
        />
        <CheckboxField
          name="requiresEnrollmentApproval"
          required
          value={values["requiresEnrollmentApproval"]}
          setFieldValue={setFieldValue}
          label={t("hbh.requiresEnrollmentApproval.label")}
        />
        <CheckboxField
          name="requiresCancellationApproval"
          required
          value={values["requiresCancellationApproval"]}
          setFieldValue={setFieldValue}
          label={t("hbh.requiresCancellationApproval.label")}
        />
      </Grid>
      {!packageData ? <AddAttachments /> : <Divider />}

      {!!packageData ? (
        <>
          <Grid>
            <ManagePackageDocuments item={packageData as IPackageRowItem} />
          </Grid>
        </>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;
