import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string;
  benefitCategoryTag: number | string;
  clientInternalNumberGOS: number | string;
  rowVersion: string;
};

const deleteCategory = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitCategoryTag,
  rowVersion,
}: Params) => {
  const response = await api.patch(
    `/benefitcategory/delete/${countryCode}/${clientInternalNumberGOS}/${benefitCategoryTag}`,
    {
      rowVersion,
    }
  );
  return response.data;
};

export function useDeleteBenefitCategory(refetch = true) {
  const queryClient = useQueryClient();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(deleteCategory, {
    mutationKey: MUTATION_KEYS.deleteBenefitCategory,
    onSuccess: () => {
      if (refetch) {
        queryClient.refetchQueries({
          queryKey: QUERY_KEYS.benefitCategories,
        });
      }
      // queryClient.invalidateQueries({ queryKey: QUERY_KEYS.benefitCategories });
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
