import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { Attachment } from "library/types";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateBenefitCategories } from "./useGetBenefitCategories";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  rowVersion: string;
};
const deleteClient = async ({
  countryCode,
  clientInternalNumberGOS,
  rowVersion,
}: Params) => {
  const response = await api.patch(
    `/client/delete/${countryCode}/${clientInternalNumberGOS}`,
    {
      rowVersion,
    }
  );
  return response.data as Attachment;
};

export function useDeleteClient() {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateBenefitCategories = useInvalidateBenefitCategories();

  return useMutation(deleteClient, {
    mutationKey: MUTATION_KEYS.deleteClient,
    onSuccess: () => {
      invalidateBenefitCategories();
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
