import { DatePicker } from "@fluentui/react-datepicker-compat";
import { useAtom } from "jotai";
import React from "react";
import { periodFromDateAtom, periodToDateAtom } from "store/UIHrPageDashboard";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { devices } from "library/constants";

const onFormatDate = (date?: Date | undefined): string => {
  return !date
    ? ""
    : ((date?.getDate() + "").length === 1 ? "0" : "") +
        date?.getDate() +
        "." +
        ((date?.getMonth() + 1 + "").length === 1 ? "0" : "") +
        (date?.getMonth() + 1) +
        "." +
        date?.getFullYear();
};

const FilterPeriod = () => {
  const [periodFromDate, setPeriodFromDate] = useAtom(periodFromDateAtom);
  const [periodToDate, setPeriodToDate] = useAtom(periodToDateAtom);
  const { t } = useTranslation();
  return (
    <Container>
      <div style={{ alignItems: "center", display: "flex" }}>
        <label>{t("hbh.period.label")}:&nbsp;</label>
      </div>
      <div
        style={{
          alignItems: "center",
          minWidth: "105px",
          flex: 1,
          display: "flex",
        }}
      >
        <DatePicker
          style={{ width: "100%", height: "20px" }}
          value={new Date(periodFromDate)}
          onSelectDate={(date) => {
            if (date)
              setPeriodFromDate(
                `${date.getFullYear()}-${(date.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")}-${date
                  .getDate()
                  .toString()
                  .padStart(2, "0")}T00:00:00Z`
              );
          }}
          onFocus={(e) => {
            e.preventDefault();
          }}
          openOnClick={false}
          maxDate={new Date()}
          formatDate={onFormatDate}
          placeholder={t("hbh.filterDate.date.placeholder")}
          title={t("hbh.filterDate.date.title")}
          disabled={false}
        />
      </div>
      <div>&nbsp;-&nbsp;</div>
      <div
        style={{
          alignItems: "center",
          minWidth: "105px",
          flex: 1,
          display: "flex",
        }}
      >
        <DatePicker
          style={{ width: "100%", height: "20px" }}
          value={periodToDate}
          onSelectDate={(date) => {
            if (date) setPeriodToDate(date);
          }}
          onFocus={(e) => {
            e.preventDefault();
          }}
          openOnClick={false}
          maxDate={new Date()}
          formatDate={onFormatDate}
          placeholder={t("hbh.filterDate.date.placeholder")}
          title={t("hbh.filterDate.date.title")}
          disabled={false}
        />
      </div>
    </Container>
  );
};

export default FilterPeriod;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-top: 10px;
  width: 100%;

  @media only screen and ${devices.md} {
    flex-direction: row;
    align-items: center;
  }
`;
