import { PeopleSwapRegular } from "@fluentui/react-icons";
import { ActionButton, Grid } from "components/styled";
import { useAtomValue } from "jotai";
import { IExcelReportResponse } from "library/types";
import { downloadFile } from "library/utils";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectedCompanyState } from "store/UIHrPage";

import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import DatePickerField from "components/controls/DatePickerField";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { formatDate } from "date-fns";
import useNotificationHandler from "hooks/useNotificationHandler";
import { generateEmployeeEnrollmentsReportByTimePeriod } from "state/queries/useGenerateEmployeeEnrollmentsReportByTimePeriod";

export function EmployeeEnrollmentsReportByTimePeriod() {
  const { t } = useTranslation();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  const selectedCompany = useAtomValue(selectedCompanyState);
  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({
    from: null,
    to: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [employeeReport, setEmployeeReport] =
    useState<IExcelReportResponse | null>(null);

  const onUpdateFormState = useCallback((name, value) => {
    setFormState((prev) => {
      return {
        ...prev,
        [name]: value ? formatDate(new Date(value), "yyyy-MM-dd") : null,
      };
    });
  }, []);

  useEffect(() => {
    setEmployeeReport(null);
  }, [selectedCompany]);

  return (
    <>
      <ActionButton
        noBorder
        appearance="subtle"
        disabled={isLoading}
        icon={<PeopleSwapRegular />}
        // onClick={async () => {
        //   if (employeeReport) {
        //     downloadFile(employeeReport, employeeReport.name);
        //     return;
        //   }
        //   setEmployeeReportLoading(true);
        //   const employeeReportData =
        //     await generateEmployeeEnrollmentsReportByTimePeriod({
        //       countryCode: Number(selectedCompany?.countryCode) || 0,
        //       clientInternalNumberGos:
        //         Number(selectedCompany?.clientInternalNumberGos) || 0,
        //     });
        //   setEmployeeReportLoading(false);
        //   setEmployeeReport(employeeReportData);
        //   downloadFile(employeeReportData, employeeReportData.name);
        // }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {t("hbh.employeeEnrollmentsReportByTimePeriod.label")}
      </ActionButton>
      {open && (
        <Dialog modalType="alert" open={open}>
          <DialogSurface>
            {isLoading && <AppSpinner />}
            <DialogBody>
              <DialogTitle
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {t("hbh.employeeEnrollmentsReportByTimePeriod.label")}
              </DialogTitle>
              <DialogContent>
                <Grid $gridColumns={2}>
                  <DatePickerField
                    required
                    name="from"
                    value={formState.from}
                    setFieldValue={onUpdateFormState}
                    label={t("hbh.from.label")}
                  />
                  <DatePickerField
                    required
                    name="to"
                    value={formState.to}
                    setFieldValue={onUpdateFormState}
                    label={t("hbh.to.label")}
                  />
                </Grid>
              </DialogContent>
              <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    appearance="secondary"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    {t("greco.cancel")}
                  </Button>
                </DialogTrigger>
                <PrimaryButton
                  disabled={isLoading || !formState.from || !formState.to}
                  onClick={async () => {
                    try {
                      setIsLoading(true);
                      const employeeReportData =
                        await generateEmployeeEnrollmentsReportByTimePeriod({
                          countryCode:
                            Number(selectedCompany?.countryCode) || 0,
                          clientInternalNumberGos:
                            Number(selectedCompany?.clientInternalNumberGos) ||
                            0,
                          payload: formState,
                        });
                      setIsLoading(false);
                      setEmployeeReport(employeeReportData);
                      downloadFile(employeeReportData, employeeReportData.name);
                      setOpen(false);
                    } catch (error) {
                      setIsLoading(false);
                      handleAddAxiosErrorNotification(error);
                    }
                  }}
                >
                  {t("hbh.generate.label")}
                </PrimaryButton>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}
    </>
  );
}
