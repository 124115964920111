import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IBenefitCategory } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  payload: IBenefitCategory[];
};

const createMultipleBenefitCategories = async ({
  countryCode,
  clientInternalNumberGOS,
  payload,
}: Params) => {
  const response = await api.post(
    `/benefitcategory/multiple/${countryCode}/${clientInternalNumberGOS}`,
    payload
  );
  return response.data as IBenefitCategory;
};

export function useCreateMultipleBenefitCategories(refetch = true) {
  const queryClient = useQueryClient();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(createMultipleBenefitCategories, {
    mutationKey: MUTATION_KEYS.createMultipleBenefitCategories,
    onSuccess: () => {
      if (refetch) {
        queryClient.refetchQueries({ queryKey: QUERY_KEYS.benefitCategories });
      }
      // queryClient.invalidateQueries({ queryKey: QUERY_KEYS.benefitCategories });
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
