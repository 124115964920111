import { FilteringCombobox } from "components/controls/FilteringCombobox";
import useGetAllCurrencyOptions from "hooks/useGetAllCurrencyOptions";
import { IRowItem } from "library/types";

type Props = {
  item: IRowItem;
  readOnly?: boolean;
};
export function CurrencyCell({ item, readOnly }: Props) {
  const currencyOptions = useGetAllCurrencyOptions();

  const currency = currencyOptions.find(
    (currency) => currency.value === item.currencyCode
  );

  if (readOnly) {
    return currency?.text;
  }

  return (
    <FilteringCombobox
      error=""
      noErrorSection
      noLabel
      setFieldValue={(name, value) => {
        const updatedItem = {
          ...item,
          isEdited: true,

          currencyCode: Number(value?.value),
        };
        item.updateState(updatedItem);
      }}
      value={currency}
      label=""
      options={currencyOptions}
      name={"currencyCode"}
    />
  );
}
