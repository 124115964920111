import { Text } from "@fluentui/react-components";
import { EditableTable } from "components/EditableTable/EditableTable";
import { columnNames } from "library/constants";
import { IBenefitImplementation, IBenefitPackage } from "library/types";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  defaultColumnSizingOptions,
  getBenefitImplementationPackagesColumns,
} from "./columns";
import { useRoleType } from "hooks/useRoleType";

type Props = {
  benefitImplementation: IBenefitImplementation;
  packageList: IBenefitPackage[];
};
export function BenefitImplementationPackages({
  benefitImplementation,
  packageList,
}: Props) {
  const { t } = useTranslation();
  const onRowClick = useCallback((row) => {
    // console.log(row, "selected package");
  }, []);

  const roles = useRoleType();
  const isReadOnlyUser = roles?.hasReadOnlyRole;

  const columns = getBenefitImplementationPackagesColumns({
    benefitImplementation,
    hasSelection: true,
    isReadOnlyUser,
    t,
  });

  const packagesTableItems = useMemo(() => {
    return packageList
      ?.map((packageItem) => {
        return {
          ...packageItem,
          id: packageItem.benefitPackageTag,
          coverPhoto: {
            name: packageItem.backgroundPicturePath,
            base64String: packageItem.backgroundPictureContent,
            type: packageItem.backgroundPictureMimeType,
          },
          defaultState: {
            ...packageItem,
            id: packageItem.benefitPackageTag,
            coverPhoto: {
              name: packageItem.backgroundPicturePath,
              base64String: packageItem.backgroundPictureContent,
              type: packageItem.backgroundPictureMimeType,
            },
          },
        };
      })
      .sort(
        (a, b) =>
          Number(a?.benefitImplementationTag) -
          Number(b?.benefitImplementationTag)
      );
  }, [packageList]);

  return (
    <div>
      <div>
        {packagesTableItems?.length ? (
          <TableWrap>
            <EditableTable
              defaultColumnSizingOptions={defaultColumnSizingOptions}
              defaultItems={packagesTableItems}
              columns={columns}
              columnNames={columnNames}
              onRowClick={onRowClick}
              selectionMode={"single"}
            />
          </TableWrap>
        ) : (
          <Text>
            {t("hbh.noImplementationPackages.label", {
              implementation: benefitImplementation?.name,
            })}
          </Text>
        )}
      </div>
    </div>
  );
}

const TableWrap = styled.div`
  margin-top: 20px;

  table {
    .selected-row {
      background-color: ${({ theme }) => theme.palette.themeLighter} !important;
    }
  }
`;
