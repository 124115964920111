import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { IUserSettings } from "library/types";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateUserAppSettings } from "./useGetUserAppSettings";

type Params = {
  payload: number[];
};

const deleteAppSettings = async ({ payload }: Params) => {
  const response = await api.delete(`/uad/deleteappsettings`, {
    data: payload,
  });

  return response.data as IUserSettings[];
};

export function useDeleteAppSettings(refetch = true) {
  const invalidateUserAppSettings = useInvalidateUserAppSettings();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(deleteAppSettings, {
    mutationKey: MUTATION_KEYS.deleteUserSettings,
    onSuccess: () => {
      if (refetch) {
        invalidateUserAppSettings();
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
