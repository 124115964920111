import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IExcelReportResponse } from "library/types";
import { UseQueryResult, useQuery } from "react-query";
import api from "service/api";

type Props = {
  enabled?: boolean;
  countryCode: number;
  clientInternalNumberGos: number;
};

export const generateEnrolledEmployeeReport = async ({
  countryCode,
  clientInternalNumberGos,
}: Props) => {
  const response = await api.get(
    `/employee/excelexport/${countryCode}/${clientInternalNumberGos}`
  );
  return response.data as IExcelReportResponse;
};

export function useGenerateEnrolledEmployeeReport({
  enabled = true,
  countryCode,
  clientInternalNumberGos,
}): UseQueryResult<IExcelReportResponse> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IExcelReportResponse, Error>(
    [QUERY_KEYS.generateEnrolledEmployeeTemplate],
    () => {
      return generateEnrolledEmployeeReport({
        countryCode,
        clientInternalNumberGos,
      });
    },
    {
      enabled: enabled,
      onError: (error: any) => {
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}
