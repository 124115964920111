import {
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Table,
  TableBody,
  TableCell,
  TableColumnId,
  TableColumnSizingOptions,
  TableHeader,
  TableHeaderCell,
  TableProps,
  TableRow,
  useTableColumnSizing_unstable,
  useTableFeatures,
  useTableSelection,
  useTableSort,
} from "@fluentui/react-components";

import { useAppTheme } from "hooks/useAppTheme";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  withPagination?: boolean;
  items: any[];
  columnsDef: any[];
  columnNames: { [x: string]: string };
  columnSizingOptionsState: TableColumnSizingOptions;
  resizableColumns?: boolean;
  onRowClick?: (row: any) => void;
  selectionMode?: "single" | "multiselect";
  SubrowComponent?: React.FC<{
    item: any;
    updateState?: (data: any) => void;
    selectionMode?: "single" | "multiselect";
    selected?: boolean;
    [x: string]: any;
  }>;
  paginationState?: {
    from: number;
    to: number;
  } | null;
} & TableProps;

export const MainTable = ({
  items,
  columnsDef,
  columnSizingOptionsState,
  columnNames,
  resizableColumns = false,
  onRowClick,
  selectionMode,
  SubrowComponent,
  paginationState = null,
  withPagination,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const theme = useAppTheme();

  const [rowItems, setRowItems] = useState<any[]>([]);

  const columns = columnsDef;

  // const selectedRows = items.filter((row) => row.selected);

  const [columnSizingOptions, setColumnSizingOptions] =
    useState<TableColumnSizingOptions>(columnSizingOptionsState);

  const updateState = React.useCallback(
    (row: any, reset?: boolean, disabled?: boolean) => {
      setRowItems((prevData) => {
        const rowIndex = prevData.findIndex((item) => item.id === row.id);

        if (rowIndex !== -1) {
          const newData = [...prevData];

          newData[rowIndex] = {
            ...row,
            isEdited: row.isEdited,
            disabled: disabled || false,
            defaultState: {
              ...row.defaultState,
            },
          };

          return newData;
        }

        return prevData;
      });
    },
    []
  );
  React.useEffect(() => {
    setRowItems(items);
  }, [items]);

  const onColumnResize = React.useCallback((_, { columnId, width }) => {
    setColumnSizingOptions((state) => ({
      ...state,
      [columnId]: {
        ...state[columnId],
        idealWidth: width,
      },
    }));
  }, []);

  const {
    getRows,
    selection: {
      allRowsSelected,
      someRowsSelected,
      toggleAllRows,
      toggleRow,
      isRowSelected,
    },
    sort: { getSortDirection, toggleColumnSort, sort },
    columnSizing_unstable: columnSizing,
    tableRef,
  } = useTableFeatures(
    {
      columns,
      items: rowItems,
    },
    [
      useTableSelection({
        selectionMode: selectionMode || "single",
        // defaultSelectedItems: new Set([0, 1]),
      }),

      useTableColumnSizing_unstable({
        columnSizingOptions,
        onColumnResize,

        autoFitColumns: false, // Set to true to enable auto-fit
      }),
      useTableSort({
        defaultSortState: { sortColumn: "id", sortDirection: "ascending" },
      }),
    ]
  );

  const headerSortProps = (columnId: TableColumnId) => ({
    onClick: (e: React.MouseEvent) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  });

  const toggleAllKeydown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === " ") {
        if (selectionMode === "multiselect") {
          toggleAllRows(e);
        }
        // toggleAllRows(e);
        e.preventDefault();
      }
    },
    [toggleAllRows]
  );

  const rowsRaw = sort(
    getRows((row: any) => {
      const selected = isRowSelected(row.item.id);
      return {
        ...row,
        onClick: (e: React.MouseEvent) => {
          toggleRow(e, row.item.id);
        },
        onKeyDown: (e: React.KeyboardEvent) => {
          if (e.key === " ") {
            e.preventDefault();
            toggleRow(e, row.item.id);
          }
        },
        selected,
        appearance: selected ? ("brand" as const) : ("none" as const),
      };
    })
  );

  const rows = paginationState
    ? rowsRaw.slice(paginationState?.from, paginationState?.to)
    : rowsRaw;

  return (
    <Table
      sortable
      aria-label="Table with sort"
      ref={tableRef}
      {...(resizableColumns ? { ...columnSizing.getTableProps() } : {})}
      {...rest}
    >
      <TableHeader>
        <MemoTableRow>
          {/* {selectionMode === "multiselect" ? (
            <TableSelectionCell
              checked={
                allRowsSelected ? true : someRowsSelected ? "mixed" : false
              }
              onClick={(e) => {
                if (selectionMode === "multiselect") {
                  toggleAllRows(e);
                }
              }}
              onKeyDown={toggleAllKeydown}
              checkboxIndicator={{ "aria-label": "Select all rows " }}
            />
          ) : null}
          {selectionMode === "single" ? (
            <TableSelectionCell type="radio" invisible />
          ) : null} */}
          {columns.map((column, index) => (
            <Menu openOnContext key={column.columnId}>
              <MenuTrigger>
                <TableHeaderCell
                  key={column.columnId + Math.random()}
                  {...(resizableColumns
                    ? {
                        ...columnSizing.getTableHeaderCellProps(
                          column.columnId
                        ),
                      }
                    : {})}
                  {...headerSortProps(column.columnId)}
                  style={{
                    ...columnSizing.getTableHeaderCellProps(column.columnId)
                      .style,
                    background: theme.palette.themeLighter,
                  }}
                >
                  {column.renderHeaderCell({
                    toggleAllRows,
                    allRowsSelected,
                    selectionMode,
                    someRowsSelected,
                    toggleAllKeydown,
                  })}
                </TableHeaderCell>
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <MenuItem
                    onClick={columnSizing.enableKeyboardMode(column.columnId)}
                  >
                    Keyboard Column Resizing
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          ))}
        </MemoTableRow>
      </TableHeader>
      <TableBody>
        {rows.length === 0 && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              {t("hbh.noData.label")}
            </TableCell>
          </TableRow>
        )}
        {rows.map(({ item, selected, appearance, onClick }) => {
          // const withSubrow =
          //   item.expanded && selected && SubrowComponent ? "with-subrow" : "";
          const withSubrow =
            item.expanded && SubrowComponent && selected && item?.showSubrow
              ? "with-subrow"
              : "";

          return (
            <React.Fragment key={item.id}>
              <MemoTableRow
                appearance={appearance}
                aria-selected={selected}
                className={selected ? `selected-row ${withSubrow}` : withSubrow}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick(e);
                  onRowClick &&
                    onRowClick({
                      ...item,
                      updateState,
                      selectionMode,
                      selected,
                    });
                }}
              >
                {columns.map((column) => {
                  return (
                    <TableCell
                      onClick={() => {}}
                      key={column.columnId}
                      {...columnSizing.getTableCellProps(column.columnId)}
                    >
                      {column.renderCell({
                        ...item,
                        updateState,
                        selectionMode,
                        selected,
                      })}
                    </TableCell>
                  );
                })}
              </MemoTableRow>
              {item.expanded &&
              SubrowComponent &&
              selected &&
              item?.showSubrow ? (
                // {item.expanded && SubrowComponent && (
                <MemoTableRow
                  appearance={appearance}
                  aria-selected={selected}
                  className={
                    selected
                      ? "selected-row sub-component-row"
                      : "sub-component-row"
                  }
                  key={item.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick(e);
                    onRowClick &&
                      onRowClick({
                        ...item,
                        updateState,
                        selectionMode,
                        selected,
                      });
                  }}
                >
                  <TableCell
                    colSpan={columns.length}
                    style={{
                      padding: "0 4px 0 1px",
                    }}
                  >
                    <SubrowComponent
                      item={item}
                      updateState={updateState}
                      selectionMode={selectionMode || "single"}
                      selected={selected}
                    />
                  </TableCell>
                </MemoTableRow>
              ) : null}
            </React.Fragment>
          );
        })}
      </TableBody>
    </Table>
  );
};

const MemoTableRow = React.memo(TableRow);
