import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { IEmployee } from "library/types";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateCompanyEmployeeViews } from "./useGetCompanyEmployeeViews";
import { useInvalidateEmployeeDetails } from "./useGetEmployeeDetails";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  employeeEmail: string;
  payload: IEmployee;
};

const updateEmployee = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeEmail,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employee/${countryCode}/${clientInternalNumberGOS}/${employeeEmail}`,
    payload
  );

  return response.data as IEmployee;
};

export function useUpdateEmployee(refetch = true) {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  const invalidateCompanyEmployees = useInvalidateCompanyEmployeeViews();
  const invalidateEmployeeDetails = useInvalidateEmployeeDetails();

  return useMutation(updateEmployee, {
    mutationKey: MUTATION_KEYS.createEmployee,
    onSuccess: () => {
      if (refetch) {
        invalidateCompanyEmployees();
        invalidateEmployeeDetails();
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
