import React from "react";
import { useTranslation } from "react-i18next";

const AgeLimitInfo = ({ ageLimitLower, ageLimitUpper }) => {
  const { t } = useTranslation();
  return (
    <span className="age-limit-info">
      {ageLimitLower || ageLimitUpper ? "(" : ""}
      {ageLimitLower && ageLimitUpper
        ? t("hbh.ageLimitRangeBoth.label", {
            ageLimitLower,
            ageLimitUpper,
          }).toString()
        : ""}
      {ageLimitLower && !ageLimitUpper
        ? t("hbh.ageLimitRangeLower.label", { ageLimitLower }).toString()
        : ""}
      {!ageLimitLower && ageLimitUpper
        ? t("hbh.ageLimitRangeUpper.label", { ageLimitUpper }).toString()
        : ""}
      {ageLimitLower || ageLimitUpper ? ")" : ""}
    </span>
  );
};

export default AgeLimitInfo;
