import CheckboxField from "components/controls/CheckboxField";
import DatePickerField from "components/controls/DatePickerField";
import InputField from "components/controls/InputField";
import { Grid } from "components/styled";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const EmployeeEnrollmentFormFields = () => {
  const { values, errors, setFieldValue } = useFormikContext<any>();
  const { t } = useTranslation();

  useEffect(() => {
    if (values["isAutoEnrollment"]) {
      // If toggleField is set to false, clear dependentField
      setFieldValue("dateOfEnrollment", null);
    }
  }, [values["isAutoEnrollment"], setFieldValue]);

  return (
    <Grid $gridColumns={2}>
      <DatePickerField
        required={values["isAutoEnrollment"] === false}
        disabled={values["isAutoEnrollment"] === true}
        error={errors["dateOfEnrollment"] as string}
        name="dateOfEnrollment"
        value={values["dateOfEnrollment"] as Date}
        setFieldValue={setFieldValue}
        label="Enrollment Date"
      />
      <CheckboxField
        key={"isAutoEnrollment"}
        name="isAutoEnrollment"
        value={values["isAutoEnrollment"]}
        setFieldValue={setFieldValue}
        label={t("hbh.isAutoEnrollment.label")}
      />
    </Grid>
  );
};

export default EmployeeEnrollmentFormFields;
