import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Params = {
  enabled?: boolean;
};

export const getImages = async () => {
  const response = await api.get(`/images`);
  return response.data;
};

export function useGetAllImages({
  enabled = true,
}: Params): UseQueryResult<string[]> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<string[], Error>(
    [QUERY_KEYS.allAppImages, enabled],
    () => getImages(),
    {
      refetchOnMount: true,
      // cacheTime: 0,
      enabled: enabled,
      onError: (error: any) => {
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}
export const useInvalidateAllImages = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({ queryKey: QUERY_KEYS.allAppImages });
};
